import React from 'react'
import { GRAND_TOTAL_KEY,USER_KEY } from "../Constant";


export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
}
export function AddLibVars(custom_params){
    let product_ids = []
    custom_params.products?.map((product) => {        
        product_ids = [...product_ids,product.product_id];
        return ("");
    }); 
    let total_value = custom_params?.totals?.filter((x) => x.name === GRAND_TOTAL_KEY)[0]?.value ||"";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    total_value = parseInt(total_value);
    console.log(total_value);
    const script = document.createElement('script');
    // let innerText = "var google_conversion_id = 956750738; var google_custom_params = "+ custom_params+ "; var google_remarketing_only = true;";
    script.innerText = "var google_tag_params = {send_to: 'AW-956750738',ecomm_pagetype: 'cart',ecomm_prodid: ["+ product_ids+"],ecomm_totalvalue: "+total_value+",}; var google_conversion_id = 956750738;var google_custom_params = window.google_tag_params;var google_remarketing_only = true;";
    // script.innerText = "";
    document.body.appendChild(script);
}

export function AddProductLibVars(custom_params){
    let sku = custom_params?.product?.product_info;
    let total_value = custom_params?.product?.discount_price || "";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    total_value = parseInt(total_value);
    const script = document.createElement('script');
    script.innerText = "var google_tag_params = {send_to: 'AW-956750738',ecomm_pagetype: 'product',ecomm_prodid: '"+sku[0]?.value+"',ecomm_totalvalue: "+total_value+",}; var google_conversion_id = 956750738;var google_custom_params = window.google_tag_params;var google_remarketing_only = true;";
    document.body.appendChild(script);
}

export function AddPurchaseWishlistLibVars(data){    
    const script = document.createElement('script');
    script.innerText = "var google_tag_params = {send_to: 'AW-956750738',ecomm_pagetype: 'purchase',ecomm_prodid: ["+ data?.order_number+"],}; var google_conversion_id = 956750738;var google_custom_params = window.google_tag_params;var google_remarketing_only = true;";
    document.body.appendChild(script);
}

export function AddWishlistLibVars(){    
    const script = document.createElement('script');
    script.innerText = "var google_tag_params = {send_to: 'AW-956750738',ecomm_pagetype: 'wishlist',}; var google_conversion_id = 956750738;var google_custom_params = window.google_tag_params;var google_remarketing_only = true;";
    document.body.appendChild(script);
}

export function AddCategoryPageLibVars(searchTerm){    
    const script = document.createElement('script');
    if(searchTerm !== ""){
        script.innerText = "var google_tag_params = {send_to: 'AW-956750738',ecomm_pagetype: 'searchresults',}; var google_conversion_id = 956750738;var google_custom_params = window.google_tag_params;var google_remarketing_only = true;";
    }else{
        script.innerText = "var google_tag_params = {send_to: 'AW-956750738',ecomm_pagetype: 'category',}; var google_conversion_id = 956750738;var google_custom_params = window.google_tag_params;var google_remarketing_only = true;";
    }
    document.body.appendChild(script);
}

export function AddCommonLibVars(data){    
    const script = document.createElement('script');
    script.innerText = "var google_tag_params = {send_to: 'AW-956750738',ecomm_pagetype: '"+data+"',}; var google_conversion_id = 956750738;var google_custom_params = window.google_tag_params;var google_remarketing_only = true;";
    document.body.appendChild(script);
}


export const checkoutGoogleEvents = (data) => {
    let products_object = [];
    let google_analytics = {};

    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
        user = JSON.parse(user);
        customerId = user.customer_id;
    }

    data.products?.map((product,index) => {        
        let total_value = product.discount_price;
        total_value = total_value.replace("$","");
        total_value = total_value.replace(/,/g, '');
        total_value = parseInt(total_value);
        let temp_products_object = {
            content_brand: product.designer_name,
            content_category: product.category_name,
            item_id: product.sku,
            item_name: product.title,
            // affiliation: "",
            currency: "USD",
            // item_category: "",
            // item_category2: "",
            price: total_value,
            quantity : product.quantity
        }
        
        if(index === 0){
            let varient = (product?.product_items?.filter((x) => x.name === "Size")[0]?.value ||"")+"-"+(product?.product_items?.filter((x) => x.name === "Color")[0]?.value ||"") ;            
            google_analytics = {
                "id": product.product_id,
                "name": product.title,
                "price": total_value,
                "variant": varient,
                "category": "Darveys/"+product.category_name,
                "brand": product.designer_name
            }
        }

        products_object = [...products_object,temp_products_object];
        return ("");
    }); 

    window.dataLayer.push({
        ecommerce: null
    }); // Clear the previous ecommerce object.
    window.dataLayer.push({
    event: "begin_checkout_GA4",
    ecommerce: {
        'customer_id': customerId,
        'membership_type': user ? 'Yes' : "Guest user",
        currency: "USD",
        items: products_object
        }
    });
    
    if (user) {
        user = JSON.parse(user);
        window.ga('set', 'dimension1', user.customer_id);
    }
    // console.log(google_analytics);
    window.ga("ec:addProduct", google_analytics);
    
    window.ga("ec:setAction", "checkout", {
        "step": 1
    });
    window.ga("send", "pageview");
    window.ga("ec:setAction", "checkout_option", {
        step: 1,
        option: "",
    });
    window.ga("send", "event", "Checkout", "Option");
    // const [googleTagManager, setgoogleTagManager] = useState({});
    // let product_ids = []
    // data.products?.map((product) => {        
    //     product_ids = [...product_ids,product.product_id];
    //     return ("");
    // }); 
    // let google_tag_params = {        
    //     send_to: 'AW-956750738',                       
    //     ecomm_pagetype: 'cart',
    //     ecomm_prodid: product_ids,        
    //     ecomm_totalvalue: data?.totals?.filter((x) => x.name === GRAND_TOTAL_KEY)[0]?.value ||"",         
    // };

  return (
    <>
    <div>
        {AddLibVars(data)}
        {AddLibrary('https://www.googleadservices.com/pagead/conversion.js')}
    </div>
    {/* <script type="text/javascript">
        let google_conversion_id = 956750738;
        let google_custom_params = {{name: "kkkkkkkkkkkkkkkkkk"}};
        let google_remarketing_only = true;
    </script>
    <script type="text/javascript" src="//www.googleadservices.com/pagead/conversion.js" async></script> */}    
    </>
  )
}

export const GoogleProductEvents = (data) => {   
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
        user = JSON.parse(user);
        customerId = user.customer_id;
    }
    let sku = data?.product?.product_info;
    let total_value = data?.product?.discount_price || "";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    total_value = parseInt(total_value);

    window.gtag('event','view_item', {
        'value': total_value,
        'items': [
          {
            'id': data?.product?.id_product, 
            'type': 'product',
            'brand': data?.product?.designer_name,
            'name': data?.product?.name,
            'category': '',
            'google_business_vertical': 'retail'
          }]
      });
    
    window.dataLayer.push({
        ecommerce: null
    }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "view_product_page_GA4",
        ecommerce: {
            'customer_id': customerId,
            'membership_type': user ? 'Yes' : "Guest user",
            items: [{
                item_id: sku[0]?.value,
                item_name: data?.product?.name || "",
                page_type: "product",
                // affiliation: "",
                currency: "USD",
                // item_category: "",
                // item_category2: "",
                price: total_value ,                        
                quantity : "1"
            }]
        }
    });



    if (user) {
        user = JSON.parse(user);
        window.ga('set', 'dimension1', user.customer_id);
    }
    window.ga("ec:addProduct", {
        "id": sku[0]?.value,
        "name": data?.product?.name || "",
        "category": "Darveys/Men",
        "brand": data?.product?.designer_name,
        "price": total_value,
        "quantity": 0,
        "list": "Single"
    });
    window.ga("ec:setAction", "click", {
        "list": "Single"
    });
    window.ga("send", "event", "Single", "click");

    document.addEventListener('DOMContentLoaded', function() {
        var AddToCartButton = document.getElementById('cart_drop');
        AddToCartButton.addEventListener('click', function() {
            // window.dataLayer.push({
            //     ecommerce: null
            // }); // Clear the previous ecommerce object.
            // window.dataLayer.push({
            //     event: "add_to_cart",
            //     ecommerce: {
            //         items: [{
            //             item_id: sku[0]?.value,
            //             item_name: data?.product?.name || "",
            //             affiliation: "",
            //             currency: "USD",
            //             item_category: "",
            //             item_category2: "",
            //             price: total_value ,                        
            //             quantity : "1"
            //         }]
            //     }
            // });
            window.gtag('event', 'conversion', {'send_to': 
            'AW-956750738/tZDVCKGAgfMBEJK3m8gD'});
            window.ga("set", "&cu", "USD");
            window.ga("ec:addProduct", {
                "id": sku[0]?.value,
                "name": data?.product?.name || "",
                "category": "Darveys/Men",
                "brand": data?.product?.designer_name,
                "price": total_value,
                "quantity": 0,
                "list": "Single"
            });
            window.ga("ec:setAction", "add");
            window.ga("send", "event", "UX", "click", "add to cart");
            });
    });
  return (
    <>
    <div>
        {AddProductLibVars(data)}
        {AddLibrary('https://www.googleadservices.com/pagead/conversion.js')}
    </div>
    </>
  )
}

export const GooglewishlistEvents = () => {    
    return (
      <>
      <div>
          {AddWishlistLibVars()}
          {AddLibrary('https://www.googleadservices.com/pagead/conversion.js')}
      </div>
      </>
    )
  }

  export const GooglePurchaseEvents = (data,gaProductsDetails) => {   
    let products_object = [];
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
        user = JSON.parse(user);
        customerId = user.customer_id;
    }
    
    let total_value = data?.order_total || "";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    total_value = parseInt(total_value);

    gaProductsDetails?.map((product) => {        
        let total_product_value = product?.price || "";
        total_product_value = total_product_value.replace("$","");
        total_product_value = total_product_value.replace(/,/g, '');
        total_product_value = parseInt(total_product_value);
        let temp_products_object = {
            content_brand: product?.brand || "",
            content_category: product?.category || "",
            item_id: product?.id || "",
            item_name: product?.name || "",
            price: total_product_value,
            quantity : product?.quantity || "",
        }
        products_object = [...products_object,temp_products_object];
        return ("");
    }); 
    
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
    event: "purchase_GA4",
    'customer_id': customerId,
    'membership_type': user ? 'Yes' : "Guest user",
    ecommerce: {
      transaction_id: data?.order_number,   
      value: total_value,
      tax: 0, //hardcoded  
      shipping: 0, //hardcoded
      currency: "USD",
      items: products_object
      }
    });

    window.gtag('event', 'conversion', {
        'send_to': 'AW-956750738/CL0OCO3Y3YsBEJK3m8gD',
        'value': total_value,
        'currency': "USD",
        'transaction_id': data?.order_number || ""
    });
  

    if(data?.payment_mode && data?.payment_mode !== "COD" ){
        if(data?.payment_status && data?.payment_status !== 'Pending'){
            window.gtag('event', 'conversion', {
                'send_to': 'AW-956750738/5dnaCKPfhKoDEJK3m8gD',
                'value': total_value,
                'currency': "USD",
                'transaction_id': data?.order_number || ""
            });          
        }    
    }

    gaProductsDetails?.map((product) => {
        window.ga("ec:addProduct", {
            "id": product?.id || "",
            "name": product?.name || "",
            "price": product?.price || "",
            "quantity": product?.quantity || "",
            "variant": product?.variant || "",
            "category": product?.category || "",
            "brand": product?.brand || ""
        });
        return ("");
    })   

    window.ga("ec:setAction", "purchase", {
        "id": data?.order_number,
        "affiliation": "Main Website Main Website Store Default Store View",
        "revenue": total_value,
        "tax": "0.0000",
        "shipping": "0.0000",
        "coupon": ""
    });
    
    window.ga("send", "pageview");

    return (
      <>
      <div>
          {AddPurchaseWishlistLibVars(data)}
          {AddLibrary('https://www.googleadservices.com/pagead/conversion.js')}
      </div>     
      </>
    )
  } 
  
  export const GoogleCategoryPageEvents = (data,searchTerm) => { 
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
        user = JSON.parse(user);
        customerId = user.customer_id;
    }
    let category_name = "";
    if(searchTerm !== ""){
        category_name = searchTerm;
    }else{
        let breadcrumbs = data?.breadcrumb || [];
        category_name = (breadcrumbs.length> 0) ? breadcrumbs[1]?.label : "";
    }
    window.gtag('event','view_item_list', {
            'customer_id': customerId,
            'membership_type': user ? 'Yes' : "Guest user",
            'page_type': 'Catalog',
            'category':  category_name,
            'google_business_vertical': 'retail'
    });
    
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_catalog_page_GA4",
      ecommerce: {
        'customer_id': customerId,
        'membership_type': user ? 'Yes' : "Guest user",
        items: [{
            'page_type': 'Catalog',
            'category':  category_name,
        }]
        }
    });

    return (
      <>
      <div>
          {AddCategoryPageLibVars(searchTerm)}
          {AddLibrary('https://www.googleadservices.com/pagead/conversion.js')}
      </div>      
      </>
    )
  }
  export const GoogleCommonEvents = (data) => {    
    return (
      <>
      <div>
          {AddCommonLibVars(data)}
          {AddLibrary('https://www.googleadservices.com/pagead/conversion.js')}
      </div>      
      </>
    )
  }

  export const GoogleAddToCartEvents = (product) => {
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
        user = JSON.parse(user);
        customerId = user.customer_id;
    }
    let sku = product?.product_info;
    let total_value = product?.discount_price || "";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    total_value = parseInt(total_value);
    window.dataLayer.push({
        ecommerce: null
    }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "add_to_cart_GA4",
        ecommerce: {
            'customer_id': customerId,
            'membership_type': user ? 'Yes' : "Guest user",
            currency: "USD",
            items: [{
                item_id: sku[0]?.value,
                item_name: product?.name || "",
                // affiliation: "",
                currency: "USD",
                // item_category: "",
                // item_category2: "",
                price: total_value ,                        
                quantity : "1"
            }]
        }
    });
    window.gtag('event', 'conversion', {'send_to': 
    'AW-956750738/tZDVCKGAgfMBEJK3m8gD'});
    window.ga("set", "&cu", "USD");
    window.ga("ec:addProduct", {
        "id": sku[0]?.value,
        "name": product?.name || "",
        "category": "Darveys/Men",
        "brand": product?.designer_name,
        "price": total_value,
        "quantity": 0,
        "list": "Single"
    });
    window.ga("ec:setAction", "add");
    window.ga("send", "event", "UX", "click", "add to cart");

  }

  export const GoogleCheckoutButtonEvents = (button_type) => {    
    if (button_type === "shipping_method") {
      window.ga("ec:setAction", "checkout_option", {
        step: 2,
        option: "",
      });
      window.ga("send", "event", "Checkout", "Option");
    }
    if (button_type === "payment") {
      window.ga("ec:setAction", "checkout_option", {
        step: 3,
        option: "",
      });
      window.ga("send", "event", "Checkout", "Option");
    }
  }
// export default GoogleEvents