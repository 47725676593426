import React from "react";
import "./Loader.css";

function Loader() {
  return (
    <div className="overlay">
      <div className="overlay__inner">
        <div className="overlay__content">
          <span className="spinnerloader"></span>
        </div>
      </div>
    </div>
  );
}

export default Loader;
