import React from "react";
import Select, { components } from "react-select";

function CustomSelect(props) {
  let text_color = props.text_color || "black";
  let customProps = { ...props };

  // by default clear and search will be disabled
  customProps.isClearable = props.isClearable || false;
  customProps.isSearchable = props.isSearchable || false;

  // Default value
  customProps.defaultValue =
    props.options.find((i) => i.value === props.defaultValue) || null;

  // Added custom class with default cs-ddl classname
  customProps.className = `cs-ddl ${props.className || ""}`;
  //all inner elements will be given a className with the provided prefix 'cs-ddl'.
  customProps.classNamePrefix = `cs-ddl`;

  // default styles, if custom style is not present
  if (!customProps.styles) {
    customProps.styles = {
      option: (provided, state) => {
        const color_str = "#f6f6f6";
        return {
          ...provided,
          backgroundColor: state.isDisabled
            ? undefined
            : state.isSelected
            ? color_str
            : state.isFocused
            ? color_str
            : undefined,
          color: state.isDisabled
            ? "#ccc"
            : state.isSelected
            ? text_color
            : state.isFocused
            ? text_color
            : text_color,
          cursor: state.isDisabled ? "not-allowed" : "default",

          ":active": {
            ...provided[":active"],
            backgroundColor: !state.isDisabled
              ? state.isSelected
                ? color_str
                : color_str
              : undefined,
          },
          border: `1px solid #e2e2e2`,
        };
      },
      control: (styles, state) => ({
        ...styles,
        fontSize: "15px",
        borderColor: "hsl(0, 0%, 80%)",
        boxShadow: "none",
        "&:hover": {
          borderColor: "hsl(0, 0%, 80%)",
        },
        // color: 'black'
      }),
      singleValue: (provided, state) => {
        // const opacity = state.isDisabled ? 0.5 : 1;
        // const transition = "opacity 300ms";

        return { ...provided, color: text_color };
      },
    };
  }
  return (
    <>
      <Select {...customProps} components={{ DropdownIndicator,Option }} />
    </>
  );
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        // BOC - Dixit Chauhan - 04-Jan-2024 - Task#143723-Need to fix the UI design issues - We have changed the dropdown icon as shared 
        src={`${process.env.PUBLIC_URL}/assets/images/icons/angle-arrow-down.png`}
        width="16"
        // EOC
        alt=""
      />
    </components.DropdownIndicator>
  );
};
/**
 * BOC 
 * @TicketId: Task#136939 - Changes in Size Guide
 * @author: Mohammad Meraj
 * @date: 28-Feb-2023
 * @use: Add this code to make actual size in bold in the mobile dropdown.
 */
const Option = (props) => {
  let optionLabel = props?.children;
  let toSearch = '(';
  let target = optionLabel.indexOf(toSearch);
  let firstPart = '';
  let secondPart = '';
  if(target > 0){
    firstPart = optionLabel.substring(0,target);    
    secondPart = optionLabel.substring(target + toSearch.length, optionLabel.length);
  }
  return (
    <components.Option {...props}>
      {target > 0 ? 
        props?.isDisabled ? 
        <span><strike><b>{firstPart}</b>{"("+secondPart}</strike></span>
        :
        <span><b>{firstPart}</b>{"("+secondPart}</span>
      :
        props?.isDisabled ? 
        <strike>{optionLabel}</strike>
        :
        <>{optionLabel}</>
      }     
    </components.Option>
  );
};

export default CustomSelect;
