import {
  appLogin,
  appRegisterUser,
  appSocialLogin,
  appLogout,
  syncRegisterUserData,
} from "../../services/AuthServices";
import { TOKEN_KEY, USER_KEY } from "../../utils/Constant";

import {
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ADD_CART_COUNT,
  LOGOUT_SUCCESS,
  CLEAR_CART_COUNT,
} from "../actions/types";
import { returnErrors } from "./errorActions";
import {commonPageEventsFB} from "../../utils/PixelEvents/FacebookEvents";
import {GoogleCommonEvents} from "../../utils/PixelEvents/GoogleEvents";

// Check token & load user
export const loadUser = () => (dispatch, getState) => {
  // User loading

  dispatch({ type: USER_LOADING });

  const token = localStorage.getItem(TOKEN_KEY);

  let user = localStorage.getItem(USER_KEY);

  user = user ? JSON.parse(user) : null;

  // const isAuthenticated = getState().auth.isAuthenticated;

  if (!token || !user) {
    return dispatch({
      type: AUTH_ERROR,
    });
  }

  return dispatch({
    type: USER_LOADED,
    payload: { ...user, token: token },
  });
};

// Login User
export const login = ({ email, password }) => {
  return async (dispatch) => {
    // call login api and get response
    return appLogin({ email, password })
      .then((response) => {
        response = JSON.parse(response.data);

        let loginUser = response?.login_user;
        if (loginUser?.status === "success") {
          document.body.setAttribute('style',''); 
          loginUser = { ...loginUser, token: response?.SID };
          // handling error in try catch block
          try {
            commonPageEventsFB('Account Login');
          } catch (e) {
            console.log(e.message);
          }
          // handling error in try catch block
          try {
            GoogleCommonEvents('account login');
          } catch (e) {
            console.log(e.message);
          }
          dispatch({
            type: ADD_CART_COUNT,
            payload: {cartCount: loginUser?.cart_count || 0},
          });

          dispatch({
            type: LOGIN_SUCCESS,
            payload: loginUser,
          });
          return Promise.resolve(true);
        } else {
          dispatch(returnErrors(loginUser.message, null, LOGIN_FAIL));
          dispatch({
            type: LOGIN_FAIL,
          });
          return Promise.resolve(false);
        }
      })
      .catch((err) => {
        dispatch(
          returnErrors(err.response.data, err.response.status, LOGIN_FAIL)
        );
        dispatch({
          type: AUTH_ERROR,
        });
        return Promise.reject();
      });
  };
};

// Social Login User
export const socialLogin = (loginData, socialType) => {
  return async (dispatch) => {
    // call login api and get response
    return appSocialLogin({ login: JSON.stringify(loginData) })
      .then((response) => {
        response = JSON.parse(response.data);

        let loginUser = response?.login_user;
        if (loginUser?.status === "success") {
          document.body.setAttribute('style',''); 
          loginUser = {
            ...loginUser,
            token: response?.SID,
            socialLoginType: socialType,
          };
          // handling error in try catch block
          try {
            commonPageEventsFB('Account Login');
          } catch (e) {
            console.log(e.message);
          }
          // handling error in try catch block
          try { 
            GoogleCommonEvents('account login');
          } catch (e) {
            console.log(e.message);
          }
          dispatch({
            type: ADD_CART_COUNT,
            payload: {cartCount: loginUser?.cart_count || 0},
          });

          dispatch({
            type: LOGIN_SUCCESS,
            payload: loginUser,
          });
          return Promise.resolve(true);
        } else {
          dispatch(returnErrors(loginUser.message, null, LOGIN_FAIL));
          dispatch({
            type: LOGIN_FAIL,
          });
          return Promise.resolve(false);
        }
      })
      .catch((err) => {
        dispatch(
          returnErrors(err.response.data, err.response.status, LOGIN_FAIL)
        );
        dispatch({
          type: AUTH_ERROR,
        });
        return Promise.reject();
      });
  };
};

// Register User
export const register = (data) => {
  return async (dispatch) => {
    // call login api and get response
    return appRegisterUser(data)
      .then((response) => {
        response = JSON.parse(response.data);

        let signupUser = response?.signup_user;
        if (signupUser?.status === "success") {
          syncRegisterUserData(data);
          document.body.setAttribute('style',''); 
          signupUser = { ...signupUser, token: response?.SID };
          // handling error in try catch block
          try {
            commonPageEventsFB('Account Create');
          } catch (e) {
            console.log(e.message);
          }
          // handling error in try catch block
          try {
            GoogleCommonEvents('account create');
          } catch (e) {
            console.log(e.message);
          }
          dispatch({
            type: REGISTER_SUCCESS,
            payload: signupUser,
          });
          return Promise.resolve(true);
        } else {
          dispatch(returnErrors(signupUser.message, null, REGISTER_FAIL));
          dispatch({
            type: REGISTER_FAIL,
          });

          return Promise.resolve(false);
        }
      })
      .catch((err) => {
        dispatch(
          returnErrors(err.response.data, err.response.status, REGISTER_FAIL)
        );
        dispatch({
          type: AUTH_ERROR,
        });
        return Promise.reject();
      });
  };
};

// logout
// export const logout = () => (dispatch) => {
  export const logout = () => {  

  return async (dispatch) => {
    dispatch({
      type: CLEAR_CART_COUNT,
    });
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    return appLogout()
      .then((response) => {
        response = JSON.parse(response.data);
        if (response?.status === "success") {          
          return Promise.resolve(true);
        }
      })
      .catch((err) => {        
        dispatch({
          type: AUTH_ERROR,
        });
        return Promise.reject();
      });
  };
  // dispatch({
  //   type: CLEAR_CART_COUNT,
  // });
  // return dispatch({
  //   type: LOGOUT_SUCCESS,
  // });
};

export const addWishlistCount = (loginUser) => {
  return {
    type: LOGIN_SUCCESS,
    payload: loginUser,
  };
};
