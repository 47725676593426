import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "../../config/app.js";

function OffersNav({ menu }) {
  const [isHovered, setIsHovered] = useState(false);

   useEffect(() => {
    /**
     * BOC
     * Task #148777 US website- Subcategories Changes
     * author Maanik Arya
     * date 23-Apr-2024
     * use_of_code We have comment the below code because in the Sale there is not any sale data present but skeleton is showing.
     */
    //  if (isHovered) {
    //    window.$(".bg-header").css("display", "block");
    //  } else {
    //    window.$(".bg-header").css("display", "none");
    //  }
    /**
     * EOC
     */
   }, [isHovered]);

  return (
    <>
      <li
        className={`hasChildren ${isHovered ? " hover" : ""}`}
        onMouseEnter={(e) => setIsHovered(true)}
        onMouseLeave={(e) => setIsHovered(false)}
      >
        {menu?.id ? (
          <Link
            to={`/catalog/${menu?.url_path}`}
            className=" site-nav__item"
            onClick={(e) => setIsHovered(false)}
          >
            {menu?.name}
          </Link>
        ) : (
          <Link to={`#0`} className=" site-nav__item">
            {menu?.name}
          </Link>
        )}

        {menu?.second_children && menu?.second_children?.length > 0 && (
          <div className="darveys-nav-children nav-children offers-nav">
            <div className="child-row">
              {menu?.second_children?.map((secondChild, sIdx) => {
                return (
                  <div
                    className="darveys-child-col child-col col-1-1"
                    key={`subMenu-${sIdx}`}
                  >
                    {secondChild?.children &&
                    secondChild?.children?.length > 0 ? (
                      <div className="list-nav-inner">
                        <h6>{secondChild?.name}</h6>
                        {secondChild?.children?.map((child, childIndex) => {
                          return (
                            <p
                              className="inner-item"
                              key={`subSubMenu-${childIndex}`}
                            >
                              <Link
                                to={`/catalog/${child?.url_path}`}
                                onClick={(e) => setIsHovered(false)}
                              >
                                {child?.name}
                              </Link>
                            </p>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="inner-item">
                        {secondChild?.name === "Deal Of The Day" ? (
                          <Link
                            to={`/${secondChild?.url_path}`}
                            onClick={(e) => setIsHovered(false)}
                          >
                            {/* {secondChild?.name} */}
                            {/* <img
                              src={
                                process.env.PUBLIC_URL +
                                  secondChild.image_data[0]?.src || ""
                              }
                              alt=""
                            /> */}
                            <LazyLoadImage
                              alt=""
                              effect="blur"
                              src={
                                config.skinURL +
                                secondChild.image_data[0]?.src || ""
                              }
                            />
                          </Link>
                        ) : (
                          <Link
                            to={`/catalog/${secondChild?.url_path}`}
                            onClick={(e) => setIsHovered(false)}
                          >
                            {/* {secondChild?.name} */}
                            {/* <img
                              src={
                                process.env.PUBLIC_URL +
                                  secondChild.image_data[0]?.src || ""
                              }
                              alt=""
                            /> */}
                            <LazyLoadImage
                              alt=""
                              effect="blur"
                              src={
                                config.skinURL +
                                secondChild.image_data[0]?.src || ""
                              }
                            />
                          </Link>
                        )}
                      </p>
                    )}
                  </div>
                );
              })}

              {/* <div className="darveys-image-menu">
                {menu?.image_data?.map((imageData, imageIdx) => {
                  return (
                    <div
                      className="darveys-image-sub-menu"
                      key={`subimageMenu-${imageIdx}`}
                    >
                      <Link to={`/catalog/${imageData?.url_path}`}>
                        <img src={imageData?.src} alt="" />
                      </Link>
                      <div className="inner-img-text">
                        <h6>{imageData?.title}</h6>
                        <p className="inner-item mt-1">
                          <Link to={`/catalog/${imageData?.url_path}`}>
                            Shop Now
                          </Link>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>
        )}
      </li>
    </>
  );
}

export default OffersNav;
