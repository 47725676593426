import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setOpenPoup } from "../../store/actions/popupActions";
import SideCartModal from "../Cart/SideCartModal";
import WideNav from "../Navbar/WideNav";
import UserMenu from "../User/UserMenu";
import desktop_menu_data from "../../data/desktop_menu_data.json";
import { logout } from "../../store/actions/authActions";
import "./DesktopHeader.css";

function DesktopHeader({ catMenus,showHeaderstatus=true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useSelector((state) => state.auth);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const rediractToWishlistPage = () => {
    if (authState?.token && authState?.isAuthenticated && authState?.user) {
      return navigate("/wishlist");
    } else {
      dispatch(setOpenPoup("login"));
    }
  };

  const redirectToWebsite = (url,country_code) => {
    dispatch(logout());
    const d = new Date();
    d.setTime(d.getTime() + (3 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    let change_country = 'change_country';
    document.cookie = change_country + "=" + country_code + ";" + expires + ";path=/";
    const currentUrl = location.pathname;
    let isCurrentPageOpen = currentUrl.includes('catalog') || currentUrl.includes('product') ? true : false ; 
    if(isCurrentPageOpen){      
      url = "https://www.darveys.com" + currentUrl;    
    }
    window.location.href = url;
  }  

  const openAuthModal = () => {
    dispatch(setOpenPoup("login"));
  }

  useEffect(() => {
    if (authState?.token && authState?.isAuthenticated && authState?.user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authState]);

  return (
    <div id="header_home" className="d-dsk">
      <div className="desktop-header">
        <div className="top-header-darveys bg-white">
          <div className="container-fluid">
            <div className="row align-items-flex-end">
              <div className="col-md-3">
                {isAuthenticated === true ? (
                  <UserMenu />
                ) : (
                  <button
                    type="button"
                    className="btn-blank login-text"
                    onClick={openAuthModal}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/Users.svg"
                      }
                      alt=""
                    />{" "}
                    Login / Sign up
                  </button>
                )}
              </div>
              <div className="col-md-6">
                <div className="logo">
                  {/* <Link to="/"> */}
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/logo-dark.svg"
                    }
                    className="img-fluid d-block mx-auto"
                    alt=""
                    onClick={(e) => navigate("/")}
                    style={{ cursor: "pointer" }}
                    width={170}
                    height={28}
                  />
                  {/* </Link> */}
                </div>
              </div>
              <div className="col-md-3">
                <ul className="right-icons">


                  <li class="nav-item dropdown">                  
                    <div class="flags-dropdown">
                      <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <img src="https://flagcdn.com/256x192/us.png" alt="US"/>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="#" onClick={()=>{redirectToWebsite('https://www.darveys.com','IN')}}>
                            <div class="inner-text">
                              <img src="https://flagcdn.com/256x192/in.png" alt="IND"/>
                              <p>India</p>
                            </div>
                          </a>
                        </li>                                              
                      </ul>
                    </div>
                  </li>

                  <li>
                    <span
                      onClick={rediractToWishlistPage}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/wishlist.png"
                        }
                        alt=""
                      /> */}

                      {authState?.user?.wishlist_count ? 
                      (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/dark-wishlist.png"
                          }
                          alt=""
                        />
                      )
                      :
                      (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/wishlist.png"
                          }
                          alt=""
                        />
                      )
                      }
                      
                      {/* {
                        authState?.user?.wishlist_count && (
                          <span className="badge badge-top custom-badge w-badge">{authState?.user?.wishlist_count || ""}</span>
                        )
                      } */}
                      {authState?.user?.wishlist_count ? 
                        (
                          <span className="badge badge-top custom-badge w-badge">{authState?.user?.wishlist_count || ""}</span>
                        )
                        :
                        (
                          ""
                        )
                      }
                    </span>
                  </li>
                  <li>
                    <SideCartModal />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {showHeaderstatus ?        
        <WideNav catMenus={desktop_menu_data} />
        :
        ""
        }
      </div>
    </div>
  );
}

export default DesktopHeader;
