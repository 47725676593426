import React, { useEffect, useState } from "react";
import {
  appSendResetPasswordOTP,
} from "../../services/AccountServices";
import { PLEASE_WAIT_TXT } from "../../utils/Constant";
import ResetPasswordOTP from "./ResetPasswordOTP";
import VerifyOTP from "./VerifyOTP";

function ForgotPassword({ active, toggleAuthFormCb }) {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  // const [otpMessageText, setotpMessageText] = useState("");
  const [step, setStep] = useState(1);
  const [verifyOtpMsg, setVerifyOtpMsg] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [token, setToken] = useState(null);
  const [isSubmitting, SetIsSubmitting] = useState(false);

  useEffect(() => {
    if (!active) {
      setStep(1);
    }
  }, [active]);

  if (!active) return null;

  const onForgotPwdSubmit = (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");
    /**
     * BOC: Validation for the email field
     * PURPOSE: To check if the email field is empty or not and if it is empty or invalid then show the error message.
     * BY: Anurag Chauhan on 19-03-2024
     */
    if(email.length === 0){
      setErrorMsg("Email is required");
      return;
    }
    if(email.length > 0 && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
      setErrorMsg("Invalid email");
      return;
    }
    /**
     * EOC: Validation for the email field
     */

    if (email) {
      // appForgotPassword(email).then((res) => {
      //   res = JSON.parse(res.data);
      //   if (res.status === "success") {
      //     setEmail("");
      //     setSuccessMsg(res.message || "");
      //   } else {
      //     setErrorMsg(res.message || "");
      //   }
      // });
      SetIsSubmitting(true);
      appSendResetPasswordOTP(email)
        .then((res) => {
          res = JSON.parse(res.data);
          if (res.status === "success") {
            setEmail("");
            // console.log(res.message);
            // setotpMessageText(res.message);
            setCustomerId(res?.customer_id);
            setVerifyOtpMsg(res.message || "");
            setStep(2);
          } else {
            setErrorMsg(res.message || "");
          }
        })
        .finally(() => SetIsSubmitting(false));
    }
  };

  const handleSetCustomerId = (customer_id) => setCustomerId(customer_id);
  const handleSetToken = (token) => setToken(token);
  const handleSetStep = (step) => setStep(step);

  return (
    <>
      {step === 1 && (
        <form onSubmit={onForgotPwdSubmit}>
          <div className="form-input contc-frm mt-25">
            <p>Enter your details to receive an OTP to reset your password.</p>
          </div>

          <div className="form-input contc-frm mt-25">
            <div className="input-group">
              <label htmlFor="Name" className="lblchange">
                Email<sup>* </sup> {/* Changed Email/Mobile Number to Email  */}
              </label>
              <input
                type="text"
                placeholder="Email*"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSubmitting}
              /> {/* Changed Email/Mobile Number to Email */}
            </div>
          </div>

          {errorMsg.length > 0 && (
            <div className="validation-advice error-msg text-danger">
              {errorMsg}
            </div>
          )}

          {successMsg.length > 0 && (
            <div className="validation-advice success-msg text-success">
              {successMsg}
            </div>
          )}

          <button
            type="submit"
            className="btn btn-primary mt-35"
            // onClick={onForgotPwdSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? PLEASE_WAIT_TXT : "RESET PASSWORD"}
          </button>
        </form>
      )}

      {/* {step === 2 && (
        <div className="form-input contc-frm mt-25">
          <p className="text-black text-center">
            Kindly check your registered email/mobile number, a link has been
            sent to reset your password.
          </p>
        </div>
      )} */}

      {step === 2 && (
        <VerifyOTP
          message={verifyOtpMsg}
          handleSetStep={handleSetStep}
          handleSetToken={handleSetToken}
          handleSetCustomerId={handleSetCustomerId}
          customerId={customerId}
        />
      )}

      {step === 3 && (
        <ResetPasswordOTP
          valid={true}
          customerId={customerId}
          token={token}
          handleSetStep={handleSetStep}
          handleSetToken={handleSetToken}
          handleSetCustomerId={handleSetCustomerId}
          toggleAuthFormCb={toggleAuthFormCb}
        />
      )}
    </>
  );
}

export default ForgotPassword;
