import React from "react";

function Contact() {
  return (
    <div id="contact_section" className="">
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="contact-us-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="heading">
                      <h4>Contact us</h4>
                    </div>
                  </div>
                  <div className="col-md-4 col-4 br-grey">
                    <div className="content-box">
                      <h6>Phone</h6>
                      <a href="tel:+1(646-630-3070)" className="hidden-link">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/Phone.svg"
                          }
                          className="d-block img-fluid mx-auto mb-3"
                          alt="Phone"
                        />
                      </a>
                      <p className="d-dsk">
                        <a href="tel:+1(646-630-3070)" className="hidden-link">
                          +1(646-630-3070)
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-4 br-grey">
                    <div className="content-box">
                      <h6>Whatsapp</h6>
                      <a
                        href="https://wa.me/+16466306544" 
                        className="hidden-link"
                        rel="noreferrer"
                        target={"_blank"}
                      >{/* Changed "+918810420296" to "+16466303070" */}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/Whatsapp.svg"
                          }
                          className="d-block img-fluid mx-auto mb-3"
                          alt="Whats App"
                        />
                      </a>
                      <p className="d-dsk">
                        <a
                          href="https://wa.me/+16466306544"
                          className="hidden-link"
                          rel="noreferrer"
                          target={"_blank"}
                        > {/* Changed "+91 88104 20296" to "+16466303070" */}
                          +1(646-630-6544) 
                        </a> {/* Changed "+91 88104 20296" to "+1(646-630-6544)" */}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-4">
                    <div className="content-box br-none">
                      <h6>Email</h6>
                      <a
                        href="mailto:assistance@darveys.com"
                        className="hidden-link"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/Email.svg"
                          }
                          className="d-block img-fluid mx-auto mb-3"
                          alt="Whats App"
                        />
                      </a>
                      <p className="d-dsk">
                        <a
                          href="mailto:assistance@darveys.com"
                          className="hidden-link"
                        >
                          assistance@darveys.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="clearfix"></div>
    </div>
  );
}

export default Contact;
