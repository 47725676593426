import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appRegisterUser } from "../../services/AuthServices";
import { register } from "../../store/actions/authActions";
import {
  ErrorsMsg,
  PLEASE_WAIT_TXT,
  // REGEX_MOBILE_NUMBER,
  REGEX_STRONG_PASSWORD,
} from "../../utils/Constant";
import FacebookAuth from "./FacebookAuth";
import GoogleAuth from "./GoogleAuth";
import { FloatingLabel, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { REGISTER_FAIL } from "../../store/actions/types";
import {isMacOs, isIOS} from "react-device-detect";
import AppleAuth from "./AppleAuth";
import { useNavigate } from "react-router-dom";
import punycode from "punycode";


/**
 * BOC
 * Task#140069-Order number 100145548 issue in order status
 * author Maanik Arya 
 * date 19-Jun-2023
 * use_of_code: This code is used to validate email address. We are using punycode to convert email address to unicode and then validating it.
 */
const schema = yup.object().shape({
  first_name: yup.string().required(ErrorsMsg.required),
  email: yup.string().required(ErrorsMsg.required).email(ErrorsMsg.validEmail)
  .test('validEmail', ErrorsMsg.validEmail, (value) => {
    if(value){
      // console.log("value", value);
      const unicodeEmail = punycode.toUnicode(value);
      // console.log("unicodeEmail", unicodeEmail);
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result =  emailRegex.test(unicodeEmail);
      // console.log("testing", testing);
      return result;
    }
    return false;
  }),

  /**
   * BOC
   * Task #147401 Need to fix the Issues mentioned by Client regarding the US Storefront.
   * author Maanik Arya
   * date 18-Mar-2024
   * Use_of_code:- We have removed the Mobile number validation from the Shipping form Modal because we are removing the Mobile number field from the Sign Up PopUP. 
   * */
  // mobile_number: yup
  //   .string()
  //   .required(ErrorsMsg.required)
  //   .matches(REGEX_MOBILE_NUMBER, ErrorsMsg.validPhone),
  /**
   * EOC
   */

  password: yup
    .string()
    .required(ErrorsMsg.required)
    .matches(REGEX_STRONG_PASSWORD, ErrorsMsg.strongPwd),
});

function Signup({ active, toggleAuthFormCb }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const error = useSelector((state) => state.error);
  useEffect(() => {
    if (error.id === REGISTER_FAIL) {
      setMsg(error.msg);
    } else {
      setMsg("");
    }
  }, [error]);

  if (!active) return null;

  const onSubmitSuccess = (
    values,
    { setSubmitting, resetForm, setFieldError }
  ) => {
    let signupData = {
      is_validate: 1,
      title: "",
      first_name: "",
      last_name: "",
      mobile_number: "",
      country_code: "+91",
      email: "",
      dob: "",
      password: "",
      user_type: "",
      social_login: "No",
      is_seller: "0",
      request_storefront: "US", // Added by Anurag Chauhan on 09-05-2024 for Task#147401 - Add request_storefront in signup API.
      ...values,
    };
    // validate email if already exists
    appRegisterUser(signupData).then((res) => {
      res = JSON.parse(res?.data);

      if (res.signup_user?.status === "success") {
        // validate mobile if already exists
        appRegisterUser({ ...signupData, is_validate: 2 }).then((res2) => {
          res2 = JSON.parse(res2?.data);
          if (res2.signup_user?.status === "success") {
            // create account
            dispatch(register({ ...signupData, is_validate: 0 }))
              .then((data) => {
                if (!data) {
                  setSubmitting(false);
                }
                navigate("/account-created");
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            // mobile already exists
            // setFieldError("moble_number", res2.signup_user?.message || "");
            setFieldError("mobile_number", res2.signup_user?.message || "");
            setSubmitting(false);
          }
        });
      } else {
        // email already exists
        setFieldError("email", res.signup_user?.message || "");
        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmitSuccess}
        initialValues={{
          first_name: "",
          email: "",
          mobile_number: "",
          password: "",
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FloatingLabel controlId="first_name" label="Name" className="mb-3">
              <Form.Control
                type="text"
                name="first_name"
                autoComplete="off"
                placeholder="Name"
                value={values.first_name}
                onChange={handleChange}
                // onBlur={handleBlur}
                isInvalid={touched.first_name && errors.first_name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.first_name}
              </Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel
              controlId="email"
              label="Email address"
              className="mb-3"
            >
              <Form.Control
                type="email"
                name="email"
                autoComplete="off"
                placeholder="Email address"
                value={values.email}
                onChange={handleChange}
                // onBlur={handleBlur}
                isInvalid={touched.email && errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </FloatingLabel>

            { /** 
             * 
              * BOC
              * Task #147401 Need to fix the Issues mentioned by Client regarding the US Storefront.
              * author Maanik Arya
              * date 18-Mar-2024
              * Use_of_code:- We have removed the Mobile number validation from the Shipping form Modal because we are removing the Mobile number field from the Sign Up PopUP.  
               
              
            <FloatingLabel
              controlId="mobile_number"
              label="Mobile number"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="mobile_number"
                autoComplete="off"
                placeholder="Mobile number"
                value={values.mobile_number}
                onChange={handleChange}
                // onBlur={handleBlur}
                isInvalid={touched.mobile_number && errors.mobile_number}
              />

              <Form.Control.Feedback type="invalid">
                {errors.mobile_number}
              </Form.Control.Feedback>
            </FloatingLabel>

            */}

            <FloatingLabel
              controlId="password"
              label="Password"
              className="mb-3"
            >
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="off"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                // onBlur={handleBlur}
                isInvalid={touched.password && errors.password}
              />

              {/* {!errors.password && ( */}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/${
                    !showPassword ? "eye-show.png" : "eye-hide.png"
                  }`}
                  alt=""
                  className="eye-icon"
                  style={{
                    width: "1.125em",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => setShowPassword((p) => !p)}
                />
              {/* )} */}

              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </FloatingLabel>

            {msg.length > 0 ? <span className="text-danger">{msg}</span> : null}

            <button
              className="btn btn-primary mt-35"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? PLEASE_WAIT_TXT : "SIGN UP"}
            </button>
          </Form>
        )}
      </Formik>

      <div className="social-login mt-55">
        <p>or sign up with</p>
        <ul className="mt-55 login-item-list">
          <li>
            <FacebookAuth />
          </li>
          <li>
            <GoogleAuth />
          </li>
          {(isMacOs || isIOS) ? <li><AppleAuth/></li> : ""}
        </ul>
        <button
          onClick={(e) => toggleAuthFormCb("login")}
          className="btn-blank text-underline mt-35 hv-acc-btn"
          type="button"
        >
          Already have an account<span className="questionmark">?</span>
        </button>
      </div>
    </>
  );
}

export default Signup;
