import React from "react";
import Header from "../../components/Header/Header";
import './ThankyouPage.css' ; 
import { Navigate, useLocation, useNavigate } from "react-router-dom";


const ChangePaymentpage  = () => {

  const navigate = useNavigate();
  const {state} = useLocation() ; 
  
  // console.log( 'ChangePaymentMode : ' , state  ) ; 

  return (
    <>
      {/* Header */}
      {!state?.isApp ?
      <Header
        mobileHeaderOptions={{
          menuIcon: false,
          wishlistIcon: true,
          darveysLargeTextIcon: true,
          searchIcon: true,
          shoppingBagIcon: true,
          backButtonIcon: true,
          pageHeadingShow: false,
          pageHeadingText: "",
          darveysSmallIcon: false,
          userAccountIcon: false,
        }}
      />
      : ""}  
      <section class="main-section">
        <h4>Advance Pending</h4>
        <p>
          Receipt against your order #{state?.res?.order_number} has been
          successfully generated!
        </p>

        {state?.res?.payment_mode === "COD" && (
          <p class="policy-msg">
            We'll soon be shipping out the advance receipt of{" "}
            <strong style={{ color: "black" }}>
              {Object.entries(state?.res?.totals).map(([key, val]) => {
                return <> {key === "advance_amount" && val}</>;
              })}
            </strong>{" "}
            against your order. We request you to kindly make the advance payment
            against the same as your items can only be confirmed with our
            international boutique(s) once the advance payment is collected.
          </p>
        )}

        { state?.res?.msg && (
        <p class="policy-msg">
          {state?.res?.msg}<br/>
          {state?.res?.msg_line_2}
        </p>
        )}

        {/* <!-- ORDER SUMMARY SECTION STARTS  --> */}
        {state?.res?.totals &&
          Object.entries(state?.res?.totals).length > 0 && (
            <div className="order-summary-section">
              <div class="collapsable-div">
                <ul class="order-summary-list">
                  {Object.entries(state?.res?.totals).map(([key, val]) => {
                    return (
                      <>
                        {key === "Sub total" && val !== 0 && (
                          <li>
                            <div class="sub-detail">
                              <div class="left">{key}</div>
                              <div class="center">:</div>
                              <div class="right">{val}</div>
                            </div>
                          </li>
                        )}
                        {key === "Gift voucher" && val !== 0 && (
                          <li>
                            <div class="sub-detail">
                              <div class="left">{key}</div>
                              <div class="center">:</div>
                              <div class="right">{val}</div>
                            </div>
                          </li>
                        )}
                        {
                          /**
                           * BOC
                           * Support #138655-Fwd: Checkout Static Pages
                           * author Mohammad Meraj
                           * date 19-May-2023
                           * use_of_code:- We have updated the code because we need to show the Advance amount in sentence case and also updated the css to move the sction in the center align.
                           */
                        }  
                        {key === "advance_amount" && val !== 0 && (
                          <strong style={{fontSize:'15px'}}>
                            <li>
                              <div class="sub-detail">
                                <div class="left">
                                  Advance as 
                                  {state?.res?.payment_mode === "Bank"
                                    ? " bank deposit"
                                    : " cash payment"}
                                </div>
                                <div class="center">:</div>
                                <div class="right">{val}</div>
                              </div>
                            </li>
                          </strong>
                        )}
                        {key === "Payable at delivery" && val !== 0 && (
                          <li>
                            <div class="sub-detail">
                              <div class="left">{key}</div>
                              <div class="center">:</div>
                              <div class="right">{val}</div>
                            </div>
                          </li>
                        )}
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

        {/* Bank Details start */}
        {state?.res?.bank_details &&
          Object.entries(state?.res?.bank_details).length > 0 && (
            <div className="custom-accordion-item">
              <div className="custom-accordion-header">
                <h2>
                  <div
                    style={{

                    }}

                    class = "bank-details-cutom-accordian-style"
                  >
                    Bank Details
                  </div>
                </h2>
              </div>
              <div className="order-summary-section">
                <div className="collapsable-div">
                  {/* <!-- BANK SUMMARY SECTION STARTS  --> */}
                  <ul className="order-summary-list">
                    {Object.entries(state?.res?.bank_details).map(
                      ([key, val]) => {
                        return (
                          <>
                            {key === "Company name" && (
                              <li>
                                <div className="sub-detail">
                                  <div className="left">{key}</div>
                                  <div className="center">:</div>
                                  <div className="right">
                                    <div className="price">{val}</div>
                                  </div>
                                </div>
                              </li>
                            )}

                            {key === "Bank name" && (
                              <li>
                                <div className="sub-detail">
                                  <div className="left">{key}</div>
                                  <div className="center">:</div>
                                  <div className="right">
                                    <div className="price">{val}</div>
                                  </div>
                                </div>
                              </li>
                            )}

                            {key === "Account number" && (
                              <li>
                                <div className="sub-detail">
                                  <div className="left">{key}</div>
                                  <div className="center">:</div>
                                  <div className="right">
                                    <div className="price">{val}</div>
                                  </div>
                                </div>
                              </li>
                            )}

                            {key === "IFSC code" && (
                              <li>
                                <div className="sub-detail">
                                  <div className="left">{key}</div>
                                  <div className="center">:</div>
                                  <div className="right">
                                    <div className="price">{val}</div>
                                  </div>
                                </div>
                              </li>
                            )}

                            {key === "MICR code" && (
                              <li>
                                <div className="sub-detail">
                                  <div className="left">{key}</div>
                                  <div className="center">:</div>
                                  <div className="right">
                                    <div className="price">{val}</div>
                                  </div>
                                </div>
                              </li>
                            )}

                            {key === "Address" && (
                              <li>
                                <div className="sub-detail">
                                  <div className="left">{key}</div>
                                  <div className="center">:</div>
                                  <div className="right">
                                    <div className="price">{val}</div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </>
                        );
                      }
                    )}
                  </ul>
                  {/* <!-- BANK SUMMARY SECTION ENDS  --> */}
                </div>
              </div>
            </div>
          )}
        {/* Bank Details Ends */}

        <p className="note-msg">
          If you wish to change the advance mode, kindly login to your 'My
          account' page to process the advance payment.
        </p>

        <div class="btn-section">
          {/**
           * BOC
           * Task#137377-Checkout Process Development for the App
           * author Mohammad MEraj
           * date 10-Apr-2023
           * use_of_code:- Adding the href to redirect to the home page to detect the URL in the app to redirect the home page on the app
           */}
          <button
            class="btn custom-solid-btn"
            data-bs-toggle="modal"
            data-bs-target="#customModal"
            onClick={() => {
              navigate("/");
            }}
          >
            CONTINUE SHOPPING
          </button>
        </div>
      </section>
    </>
  );
}

export default ChangePaymentpage; 
