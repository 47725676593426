import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { setOpenPoup } from "../../store/actions/popupActions";
import "./MobileHeaderSideMenu.css";
import MobileHeaderSideSubMenu from "./MobileHeaderSideSubMenu";
import Placeholder from '../Placeholders/Placeholder'
import { Link, useNavigate, useLocation } from "react-router-dom"; // Imported Link to make the anchor tag work as a react-router-dom link
import { logout } from "../../store/actions/authActions";

Modal.setAppElement("body");

function MobileHeaderSideMenu({ openModal = false, catMenus }) {
  const [isModelOpen, setIsModalOpen] = useState(openModal);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [subMenuData, setSubMenuData] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const [showSkeleton, setShowSkeleton] = useState(false);

  const [windowOffset, setwindowOffset] = useState();

  useEffect(() => {
    if (authState?.token && authState?.isAuthenticated && authState?.user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authState]);

  useEffect(() => {
    if(catMenus &&  catMenus.length > 0) {
      setShowSkeleton(false);
    }
    else {
      setShowSkeleton(true);
    }
  }, [catMenus])

  const toggle = () => {
    if (isModelOpen) {
      setShowSubMenu(false);
      setSubMenuData({});
    }
    if(isModelOpen){
      document.body.setAttribute('style',''); 
      // window.scrollTo(0,windowOffset);
      window.scrollTo({
        left: 0,
        top: windowOffset || 0,
        behavior: "instant",
      });
    }else{
      setwindowOffset(window.scrollY);
      document.body.setAttribute('style',`position: fixed; top: -${window.scrollY}px;left:0;right:0;`);
    }
    setIsModalOpen(!isModelOpen);
  };

  const onMobileLoginClick = () => {
    toggle();
    dispatch(setOpenPoup("login"));
  };

  const onMobileSignupClick = () => {
    toggle();
    dispatch(setOpenPoup("signup"));
  };

  // const onAuthenticBtnClick = () => {
  //   toggle();
  //   dispatch(setOpenPoup("authentic"));
  // };

  const showMainMenuCb = () => {
    setShowSubMenu(false);
    setSubMenuData({});
  };

  const onMobileLogoutClick = () => {
    dispatch(logout());
  };

  const onShowSubMenuClick = (childrenData) => {
    setSubMenuData(childrenData);
    setShowSubMenu(true);
  };
  const redirectToWebsite = (url,country_code) => {    
    dispatch(logout());
    const d = new Date();
    d.setTime(d.getTime() + (3 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    let change_country = 'change_country';
    document.cookie = change_country + "=" + country_code + ";" + expires + ";path=/";
    const currentUrl = location.pathname;
    let isCurrentPageOpen = currentUrl.includes('catalog') || currentUrl.includes('product') ? true : false ; 
    if(isCurrentPageOpen){      
      url = "https://www.darveys.com" + currentUrl;    
    }
    window.location.href = url;
  } 

  // Commented below code as it is not being used anywhere.
  const redirectToPage = (url) => {
    if (url) {
      toggle();
      return navigate(url);
    }
  };

  const customStyles = {
    content: {
      inset: "unset",
      height: "100%",
      position: "fixed",
      margin: "auto",
      padding: '0px',
      right: 'unset !important',
      left: '0px !important',
      width: "300px",
    },
    overlay: { zIndex: "9999", backgroundColor: "rgb(0 0 0 / 75%)" },
  };
  return (
    <>
      <button type="button" className="btn-blank mb-home" onClick={toggle} style={{zIndex:"1",padding:"1em",margin:"-1em"}}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/icons/Home-Menu-icon.png`} alt="" />
      </button>

      <Modal
        portalClassName={"side_mobile_menu_modal"}
        closeTimeoutMS={300}
        isOpen={isModelOpen}
        toggle={toggle}
        style={customStyles}
        onRequestClose={toggle}
        shouldCloseOnOverlayClick={true}
        id="side_mobile_menu_modal"
      >
        <div className="menu-box">
          <div className="mobile-menu-img">
            <a href="#0" className="mb-wishlist">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/mobile-header-logo.jpg`}
                className="img-fluid mx-auto d-block"
                alt=""
              />
            </a>
          </div>

          {showSubMenu === false && (
            <div className="mobile-menu-items-main" id="mobile-menu-items-main">
              <ul id="inner-nav-items">
                {showSkeleton
                  ? Array.from(Array(5), (e, i) => {
                      return (
                        <li
                          key={`mainMenu1-${i}`}
                          className="placeholder-no-after"
                        >
                          <Placeholder variant="text" width="50%" />
                        </li>
                      );
                    })
                  : catMenus?.map((menu, idx) => {
                      return (
                        <li key={`mainMenu-${idx}`}>
                          <button
                            onClick={(e) => onShowSubMenuClick(menu)}
                            className="btn-blank"
                          >
                            {menu?.name}
                          </button>
                        </li>
                      );
                    })}
              </ul>
              <div className="other-menu-items">                
                <div class="flags-dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://flagcdn.com/256x192/us.png" alt="US"/>
                    <span style={{padding:"20px"}}>United States</span>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#" onClick={()=>{redirectToWebsite('https://www.darveys.com','IN')}}>
                        <div class="inner-text">
                          <img src="https://flagcdn.com/256x192/in.png" alt="IND"/>
                          <p>India</p>
                        </div>
                      </a>
                    </li>                                          
                  </ul>
                </div>                        
              </div>      
              <div className="other-menu-items">
                {true && (
                  <p>
                    <a
                      href=""
                      onClick={() => redirectToPage("/accounts/main-profile")}
                    >
                      My Account
                    </a>
                  </p> 
                )} {/* Changed Anchor tag to React Router Dom Link  */}

                <p>

                </p>

                <p className="mt-15">
                  <a
                    href=""
                    onClick={() => redirectToPage("/about-us")}
                  >
                    About Darveys
                  </a>
                </p> {/* Changed Anchor tag to React Router Dom Link And 100% Authentic to About Darveys  */}

                <p className="mt-15">
                  <a
                    href=""
                    onClick={() => redirectToPage("/contact-us?m=1")}
                  >
                    Contact us
                  </a>
                </p> {/* Changed Anchor tag to React Router Dom Link  */}
              </div>

              <div className="other-menu-items pl-20 pt-10">
                {isAuthenticated ? (
                  <>
                    <p>
                      <button
                        onClick={onMobileLogoutClick}
                        className="btn-bordered mt-15"
                      >
                        Logout
                      </button>
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      <button
                        onClick={onMobileSignupClick}
                        className="btn-bordered-grey mt-15"
                      >
                        Sign up
                      </button>
                    </p>
                    <p>
                      <button
                        onClick={onMobileLoginClick}
                        className="btn-primary mt-15"
                      >
                        Login
                      </button>
                    </p>
                  </>
                )}
              </div>
            </div>
          )}

          {showSubMenu && (
            <MobileHeaderSideSubMenu
              subMenu={subMenuData}
              showMainMenuCb={showMainMenuCb}
              modalToggleCb={toggle}
            />
          )}

          {/* <button onClick={toggle} className="btn-blank close-header-btn">
            <img src="../assets/images/icons/cross_white.svg" alt="" />
          </button> */}
        </div>
      </Modal>
    </>
  );
}

export default MobileHeaderSideMenu;
