import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useScroll from "../../hooks/useScroll";
import { setOpenPoup } from "../../store/actions/popupActions";
// import AuthenticModal from '../Authentic/AuthenticModal';
import SideCartModal from "../Cart/SideCartModal";
import SearchModal from "../Search/SearchModal";
import MobileHeaderSideMenu from "./MobileHeaderSideMenu";
import mobile_menu_data from "../../data/mobile_menu_data.json";
import {appGetMenuDesigners} from "../../services/AccountServices";
import {React, useEffect , useState} from "react";


// mobileHeaderOptions:
// {
//   menuIcon:true,
//   wishlistIcon: true,
//   darveysLargeTextIcon: true,
//   searchIcon: true,
//   shoppingBagIcon: true,
//   backButtonIcon: true,
//   pageHeadingShow: false,
//   pageHeadingText: '',
//   darveysSmallIcon: true,
//   userAccountIcon: true
// }
function MobileHeader({ catMenus, mobileHeaderOptions,backfunction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const [isScroll] = useScroll();

  const redirectToWishlistPage = () => {
    if (authState?.token && authState?.isAuthenticated && authState?.user) {
      return navigate("/wishlist");
    } else {
      dispatch(setOpenPoup("login"));
    }
  };

  useEffect(() => {
    appGetMenuDesigners().then((res)=>{
      res = JSON.parse(res?.data);
      if(res.status){
        var designerPageDataResponse = res?.data ;
        // setDesignerPageData(DesignerPageDataResponse);
        console.log( "testing" , mobile_menu_data );
        mobile_menu_data.map(data => {
          if(data.name == "Designers"){
            data.second_children = designerPageDataResponse;
          }
        });
        console.log( "updated testing" , mobile_menu_data );
      }
    });
  }, []);

  return (
    <>
      <div id="header_mobile" className="d-mbl">
        {/**
          * BOC
          * Task#143723-Need to fix the UI design issues
          * author Dixit Chauhan
          * date 15-Dec-2023 
          * use_of_code: Shopping bag text in center position and back button change into cross.
          */}
        <div
          className={`mobile-header ${
            isScroll && !mobileHeaderOptions.headerNotSticky ? " sticky " : ""
          } ${
            mobileHeaderOptions?.checkoutPageHeadingText && mobileHeaderOptions.checkoutPageHeadingText == 'Shopping bag' ? " checkout-mobile-header " : ""
          }`}
        >
          <div className="container-fluid">
            <div className="row">
              {/* Back Button */}
              {mobileHeaderOptions && mobileHeaderOptions.backButtonIcon && (
                <div className="col-1">
                  {(mobileHeaderOptions?.checkoutPageHeadingText && (mobileHeaderOptions?.checkoutPageHeadingText === 'Payment' || mobileHeaderOptions?.checkoutPageHeadingText === 'Shipping Details'))? 
                  <button
                    className="btn-blank mb-wishlist bck-arw-hdr"
                    onClick={() => backfunction()}
                    style={{zIndex:"1",padding:"1.5em",margin:"-1.5em"}}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/angle-arrow-left.png"
                      }
                      alt=""
                      style={{marginTop: "-4px"}}
                    />
                  </button>
                  :
                  
                  <button
                    className="btn-blank mb-wishlist bck-arw-hdr"
                    onClick={() =>  {(mobileHeaderOptions?.extraMessagesPage ? window.location.href = "https://www.darveys.com/" : navigate(-1) ) } }
                    style={{zIndex:"1",padding:"1.5em",margin:"-1.5em"}}
                  >
                    {/**
                      * BOC
                      * Task#143723-Need to fix the UI design issues
                      * author Dixit Chauhan
                      * date 15-Dec-2023 
                      * use_of_code: Shopping bag text in center position and back button change into cross.
                      */}
                    <img
                      src= "https://skin.darveys.com/assets/images/angle-arrow-left.png"
                      alt=""
                      style={{marginTop: "-4px"}}
                    />
                  </button>
                  }                  
                </div>
              )}

              {/* Cross Button */}
              {mobileHeaderOptions && mobileHeaderOptions.crossButtonIcon && (
                <div className="col-1">
                  <button
                    className="btn-blank mb-wishlist cross-button"
                    onClick={() => navigate(-1)}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/Cross-Button.png"
                      }
                      alt=""
                    />
                  </button>
                </div>
              )}

              {/* Mobile Menu */}
              {mobileHeaderOptions && mobileHeaderOptions.menuIcon && (
                <div className="col-1">
                  <MobileHeaderSideMenu catMenus={mobile_menu_data} />

                  {/* Authentic Modal Content to show */}
                  {/* <AuthenticModal /> */}
                </div>
              )}

              {/* Wishlist */}
              {mobileHeaderOptions && mobileHeaderOptions.wishlistIcon && (
                <div className="col-1">
                  <span
                    onClick={redirectToWishlistPage}
                    className="mb-wishlist"
                    style={{ cursor: "pointer" }}
                  >
                    {/* <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/Wishlist-button.png"
                      }
                      alt=""
                    /> */}

                    {authState?.user?.wishlist_count ? 
                    (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/dark-wishlist.png"
                        }
                        alt="" className="dark-wishlisticon"
                      />
                    )
                    :
                    (
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/Wishlist-button.png"
                        }
                        alt=""
                      />
                    )
                    }

                    {/* {authState?.user?.wishlist_count && (
                      <span className="badge badge-top custom-badge wishlist-badge">
                        {authState?.user?.wishlist_count || ""}
                      </span>
                    )} */}
                    {authState?.user?.wishlist_count ? 
                      (
                        <span className="badge badge-top custom-badge wishlist-badge">
                          {authState?.user?.wishlist_count || ""}
                        </span>
                      )
                      :
                      (
                        ""
                      )
                    }
                  </span>
                </div>
              )}

              {/* Heading Text */}
              {mobileHeaderOptions && mobileHeaderOptions.pageHeadingShow && (
                <div className="col-9">
                  <div className={"logo-mb "+ ((mobileHeaderOptions && mobileHeaderOptions.crossButtonIcon) ? "text-center":"") }>
                    <h1 className="header-truncate">{mobileHeaderOptions.pageHeadingText || ""}</h1>
                  </div>
                </div>
              )}

              {/* Darveys Large Text Icon */}
              {mobileHeaderOptions && mobileHeaderOptions.darveysLargeTextIcon && (
                <div className="col-8">
                  <div className="logo-mb">
                    <Link to="/">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/logo-dark.png"
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              )}

              {/* Extra space in center */}
              {/* {mobileHeaderOptions &&
                  !mobileHeaderOptions.darveysLargeTextIcon && (
                    <div className="col-3">&nbsp;</div>
                  )} */}

              <div className="col-2">
                <ul
                  className="header-icon-list align-items-center"
                  style={{ float: "right" }}
                >
                  {/* Search */}
                  {mobileHeaderOptions && mobileHeaderOptions.searchIcon && (
                    <li>
                      <SearchModal />
                    </li>
                  )}

                  {/* Small Darveys Icon */}
                  {mobileHeaderOptions && mobileHeaderOptions.darveysSmallIcon && (
                    <li>
                      <button
                        className="btn-blank mb-wishlist cart-icon-img"
                        type="button"
                        onClick={() => navigate("/")}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/darveys-d.png"
                          }
                          alt=""
                        />
                      </button>
                    </li>
                  )}

                  {/* Shopping Bag */}
                  {mobileHeaderOptions &&
                    mobileHeaderOptions.rightWishlistIcon && (
                      <li>
                        <button
                          className="btn-blank mb-wishlist cart-icon-img"
                          type="button"
                          onClick={redirectToWishlistPage}
                        >
                          {/* <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/Wishlist-button.png"
                            }
                            className="wishlist-icon-inner"
                            alt=""
                          /> */}

                          {authState?.user?.wishlist_count ? 
                          (
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/dark-wishlist.png"
                              }
                              alt=""
                            />
                          )
                          :
                          (
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/Wishlist-button.png"
                              }
                              alt="" className="inner-wishlist"
                            />
                          )
                          }

                          {/* {authState?.user?.wishlist_count && (
                            <span className="badge badge-top custom-badge wishlist-badge-1">
                              {authState?.user?.wishlist_count || ""}
                            </span>
                          )} */}
                          {authState?.user?.wishlist_count ?
                            (
                              <span className="badge badge-top custom-badge wishlist-badge-1">
                                {authState?.user?.wishlist_count || ""}
                              </span>
                            )
                            :
                            (
                              ""
                            )
                          }
                        </button>
                      </li>
                    )}
                    
                  {/* Shopping Bag */}
                  {mobileHeaderOptions && mobileHeaderOptions.shoppingBagIcon && (
                    <li>
                      <SideCartModal />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer-grey d-mbl"></div>
    </>
  );
}

export default MobileHeader;
