import { FACEBOOK_PIXEL_ID,USER_KEY,GRAND_TOTAL_KEY } from "../Constant";
export const checkoutEventsFB = (data) => {
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
    user = JSON.parse(user);
    customerId = user.customer_id;
    }
    let user_details = {};
    let page_view = {};
    let initiate_checkout = {};
    let products_object = [];
    if(user){
        user_details = {
            "em": user ? user.email : "",
            "fn": user ? user.first_name : "",
            "pn": user ? user.mobile_number : ""
        }    
    }

    page_view = {
        customer_Ids : user ? [customerId] : [],
        membership_type : user ? 'Yes' : "Guest user",
        total_order : user ? user.total_order : "",
    }
    data.products?.map((product) => {
        let temp_products_object = {
            content_brand: product.designer_name,
            content_name: product.title,
            content_category: product.category_name,
            id: [product.product_id],
        }
        products_object = [...products_object,temp_products_object];
        return ("");
    });  
    let total_value = data?.totals?.filter((x) => x.name === GRAND_TOTAL_KEY)[0]?.value ||"";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    initiate_checkout = {
        customer_Ids : user ? [customerId] : [],
        contents: products_object,
        content_type: 'product_group',
        value: total_value,
        currency: "USD"
    }

    window.fbq('init',FACEBOOK_PIXEL_ID,user_details);
    window.fbq('track', 'PageView',page_view);
    window.fbq('trackCustom', 'InitiateCheckout',initiate_checkout);
    document.addEventListener('DOMContentLoaded', function () {
        //outbrain pixel code..
            var billing_button = document.getElementById('billing_button');
            billing_button.addEventListener('click', function(){
                window.obApi('track', 'ProceedToPayment');
            });
            var proceedToCheckout = document.getElementById('proceed-to-checkout');
            proceedToCheckout.addEventListener('click', function(){
                window.obApi('track', 'ProoceedToCheckout');
            });
    });
    <noscript><img height="1" width="1" style={{display:'none'}} src="https://www.facebook.com/tr?id=289894514879009&ev=InitiateCheckout&noscript=1" alt=""/></noscript> 
}

export const productPageEventsFB = (data) => {
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
    user = JSON.parse(user);
    customerId = user.customer_id;
    }
    let user_details = {};
    let page_view = {};
    let sku = data?.product?.product_info;
    let product_id = data?.product?.id_product;
    let total_value = data?.product?.discount_price || "";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    total_value = parseInt(total_value);
    let view_content = {
        customer_Ids : user ? [customerId] : [],
        membership_type : user ? 'Yes' : "Guest user",
        content_brand: data?.product?.designer_name,
        content_name: data?.product?.name,
        content_category: "",
        content_ids: [sku[0]?.value],
        content_type: 'product_group',
        value: total_value,
        currency: "USD"
    };
    // let products_object = [];
    if(user){
        user_details = {
            "em": user ? user.email : "",
            "fn": user ? user.first_name : "",
            "pn": user ? user.mobile_number : ""
        }    
    }
    page_view = {
        customer_Ids : user ? [customerId] : [],
        membership_type : user ? 'Yes' : "Guest user",
        total_order : user ? user.total_order : "",
    }


    window.fbq('init',FACEBOOK_PIXEL_ID,user_details);
    window.fbq('track', 'PageView',page_view);
    window.fbq('track', 'ViewContent', view_content);

    // adroll code
    if (user) {
        window.adroll.track('productView', { "products": [{ "product_id": product_id }] });
        window.__adroll.record_user(window.adroll_custom_data = { "customer_type": "Yes", "customer_id": customerId, "telephone": user.mobile_number ? user.mobile_number : "", "page_type": "Product" });
    } else {
        window.adroll.track('productView', { "products": [{ "product_id": product_id }] });
        window.__adroll.record_user(window.adroll_custom_data = { "customer_type": "Guest user", "page_type": "Product" });
    }
    // End of adroll code




    document.addEventListener('DOMContentLoaded', function () {
        var AddToCartButton = document.getElementById('cart_drop');
        AddToCartButton.addEventListener('click', function(){
            window.fbq('trackCustom', 'AddToCart', {
                customer_id: [user ? [customerId] : []],
                membership_type: user ? 'Yes' : "Guest user",
                content_brand: data?.product?.designer_name,
                content_name: data?.product?.name,
                content_category: "",               
                content_ids: [sku[0]?.value],
                content_type: 'product_group',                      
                value: data?.product?.discount_price,
                currency: "USD"
            });      
            // Linkedin Pixel Code
            AddToCartButton.parentNode.innerHTML = AddToCartButton.parentNode.innerHTML + '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2817106&conversionId=3634722&fmt=gif" />';
            // End of Linkedin Pixel Code
            
            // window.qp('track', 'AddToCart'); // Call this function when inline action happens);            
            // window._tfa.push({notify: 'event', name: 'add_to_cart', id: 1322265});
            // AddToCartButton.parentNode.innerHTML = AddToCartButton.parentNode.innerHTML + '<img src="https://trc.taboola.com/1322265/log/3/unip?en=add_to_cart" width="0" height="0" style="display:none"/>';
        });
    });
    <noscript>
        <img height="1" width="1" style={{display:'none'}} alt="" src="https://www.facebook.com/tr?id=289894514879009&ev=ViewContent&noscript=1" />
    </noscript>
}

export const addToWishlistEventsFB = (data) => {
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
    user = JSON.parse(user);
    customerId = user.customer_id;
    }
    let user_details = {};
    let page_view = {};
    let products_object = [];
    data.wishlist_products?.map((product) => {
        let total_value = product.discount_price || "";
        total_value = total_value.replace("$","");
        total_value = total_value.replace(/,/g, '');
        let temp_products_object = {
            content_brand: product.designer_name,
            content_name: product.title,
            content_category: "",
            id: [product.product_id],
            value: total_value,
            currency: "USD",
        }
        products_object = [...products_object,temp_products_object];
        return ("");
    });

    let add_wishlist = {
        customer_Ids : user ? [customerId] : [],
        content_type: 'product_group',
        contents: products_object,
        currency: "USD"
    };
    if(user){
        user_details = {
            "em": user ? user.email : "",
            "fn": user ? user.first_name : "",
            "pn": user ? user.mobile_number : ""
        }    
    }
    page_view = {
        customer_Ids : user ? [customerId] : [],
        membership_type : user ? 'Yes' : "Guest user",
        total_order : user ? user.total_order : "",
    }



    window.fbq('init',FACEBOOK_PIXEL_ID,user_details);
    window.fbq('track', 'PageView',page_view);
    window.fbq('track', 'AddToWishlist',add_wishlist);
    <noscript><img height="1" width="1" style={{display:'none'}} alt="" src="https://www.facebook.com/tr?id=289894514879009&ev=AddToWishlist&noscript=1" /></noscript>    
}

export const onPurchaseEventsFB = (data,productsIds) => {
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
    user = JSON.parse(user);
    customerId = user.customer_id;
    }
    let user_details = {};
    let page_view = {};
    let purchase_data = {};
    if(user){
        user_details = {
            "em": user ? user.email : "",
            "fn": user ? user.first_name : "",
            "pn": user ? user.mobile_number : ""
        }    
    }
    page_view = {
        customer_Ids : user ? [customerId] : [],
        membership_type : user ? 'Yes' : "Guest user",
        total_order : user ? user.total_order : "",
    }
    let total_value = data?.order_total || "";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    let payment_mode = "";
    if(data?.payment_mode && data?.payment_mode === "COD" ){
        payment_mode = "COD";
    }else{
        if(data?.payment_status && data?.payment_status === 'Pending'){
            payment_mode = "Prepaid Pending";
        }else{
            payment_mode = "Prepaid Success";
        }        
    }

    purchase_data = {
        content_ids: productsIds,
        customer_Ids : user ? [customerId] : [],
        transaction_ids:data.order_number,
        content_type: 'product_group',
        value: total_value,
        currency: "USD",
        payment_type: payment_mode
    }


    window.fbq('init',FACEBOOK_PIXEL_ID,user_details);
    window.fbq('track', 'PageView',page_view);
    window.fbq('trackCustom', 'Purchase',purchase_data);

    // adroll code
    window.adroll.track("pageView", {
        "order_id": data?.order_number,
        "conversion_value": parseInt(total_value),
        "currency": "USD"
    });
    window.__adroll.record_user(window.adroll_custom_data = {"payment_type": payment_mode });
    // End of adroll code

    <noscript><img height="1" width="1" style={{display:'none'}} alt="" src="https://www.facebook.com/tr?id=289894514879009&ev=Purchase&noscript=1" /></noscript>
}

export const categoryPageEventsFB = (data,searchTerm) =>{
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
    user = JSON.parse(user);
    customerId = user.customer_id;
    }
    let user_details = {};
    let page_view = {};
    let view_category = {};
    let search_category = {};
    if(user){
        user_details = {
            "em": user ? user.email : "",
            "fn": user ? user.first_name : "",
            "pn": user ? user.mobile_number : ""
        }    
    }
    page_view = {
        customer_Ids : user ? [customerId] : [],
        membership_type : user ? 'Yes' : "Guest user",
        total_order : user ? user.total_order : "",
    }
    if(searchTerm !== ""){
        search_category = {
            search_string: searchTerm,
            content_ids: [],
            content_type: 'product',
            customer_Ids : user ? [customerId] : [],
            membership_type : user ? 'Yes' : "Guest user",
        }
    }else{
        // let breadcrumbs = data?.breadcrumb || [];
        view_category = {
            // content_category:  (breadcrumbs.length> 0) ? breadcrumbs[1]?.label : "",
            content_category:  data?.fproducts?.category_name ? data?.fproducts?.category_name : "",
            customer_Ids : user ? [customerId] : [],
            membership_type : user ? 'Yes' : "Guest user",
        }
    }    

    window.fbq('init',FACEBOOK_PIXEL_ID,user_details);
    window.fbq('track', 'PageView',page_view);
    if(searchTerm !== ""){
        window.fbq('track', 'Search',search_category);
    }else{    
        window.fbq('trackCustom', 'ViewCategory',view_category);
    }
    <noscript><img height="1" width="1" style={{display:'none'}} alt="" src="https://www.facebook.com/tr?id=289894514879009&ev=ViewCategory&noscript=1" /></noscript>
}

export const commonPageEventsFB = (page_type) => {
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
    user = JSON.parse(user);
    customerId = user.customer_id;
    }
    let user_details = {};
    let page_view = {};        
    if(user){
        user_details = {
            "em": user ? user.email : "",
            "fn": user ? user.first_name : "",
            "pn": user ? user.mobile_number : ""
        }    
    }
    page_view = {
        customer_Ids : user ? [customerId] : [],
        membership_type : user ? 'Yes' : "Guest user",
        page_type : page_type,
        total_order : user ? user.total_order : "",
    }

    window.fbq('init',FACEBOOK_PIXEL_ID,user_details);
    window.fbq('track', 'PageView',page_view);    
    <noscript><img height="1" width="1" style={{display:'none'}} alt="" src="https://www.facebook.com/tr?id=289894514879009&ev=PageView&noscript=1" /></noscript>
}

export const addToCartEventsFB = (product) => {
    let user = localStorage.getItem(USER_KEY);
    let customerId = 0;
    if (user) {
    user = JSON.parse(user);
    customerId = user.customer_id;
    }
    let sku = product?.product_info;
    let total_value = product?.discount_price || "";
    total_value = total_value.replace("$","");
    total_value = total_value.replace(/,/g, '');
    window.fbq('trackCustom', 'AddToCart', {
        customer_id: [user ? [customerId] : []],
        membership_type: user ? 'Yes' : "Guest user",
        content_brand: product?.designer_name,
        content_name: product?.name,
        content_category: "",               
        content_ids: [sku[0]?.value],
        content_type: 'product_group',                      
        value: total_value,
        currency: "USD"
    });
}