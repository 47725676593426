import config from "../config/app";
import * as API from "../utils/ApiHelper";
import { USER_KEY } from "../utils/Constant";

const { isProd } = config;

const API_ENDPOINT = isProd
  ? config.production.api_endpoint
  : config.development.api_endpoint;

export const appGetDiscountVouchers = (data) => {
  const url = `${API_ENDPOINT}/appGetDiscountVouchers`;
  return API.post(url, data);
};

export const appGetGVDetails = (data) => {
  const url = `${API_ENDPOINT}/appGetGVDetails`;

  return API.post(url, data);
};

export const appGetCustomerAddress = (data) => {
  const url = `${API_ENDPOINT}/appGetCustomerAddress`;

  return API.post(url, data);
};

// Get Regions
export const appGetRegions = (countryId = "US") => {
  const url = `${API_ENDPOINT}/appGetRegions`;
  // preparing post data to send
  let data = {
    country_id: countryId,
  };
  return API.post(url, data);
};

/**
 * BOC
 * Task #146356 Resolve Identified Failure Case Bugs for Stripe
 * author Maanik Arya
 * date 22-feb-2024
 * use_of_code :- Get Countries Data fro appGetCountries API to show in the country dropdown of the billing address form.
 */ 
export const appGetCountries = () => {
  const url = `${API_ENDPOINT}/appGetCountries`;
  // preparing post data to send
  let data = {
    // country_id: countryId,
  };
  return API.post(url, data);
};
/**
  * EOC
 */

export const appAddAddress = (address_data) => {
  const url = `${API_ENDPOINT}/appAddAddress`;

  let user = localStorage.getItem(USER_KEY);
  if (user) {
    user = JSON.parse(user);
  }
  let data = {
    country_id: "US",
    user_email: user ? user.email : "",
    ...address_data,
    shipping_address: JSON.stringify(address_data),
  };

  return API.post(url, data);
};

export const appRemoveAddress = (address_id) => {
  const url = `${API_ENDPOINT}/appRemoveAddress`;
  let data = {
    id_shipping_address: address_id,
    country_id: "IN",
  };

  return API.post(url, data);
};

export const appUpdatePrimaryAddress = (address_id) => {
  const url = `${API_ENDPOINT}/appUpdatePrimaryAddress`;
  let data = {
    id_shipping_address: address_id,
    country_id: "IN",
  };
  return API.post(url, data);
};

export const appUpdateAddress = (address_data, address_id) => {
  const url = `${API_ENDPOINT}/appUpdateAddress`;

  let user = localStorage.getItem(USER_KEY);
  if (user) {
    user = JSON.parse(user);
  }
  let data = {
    shipping_address: JSON.stringify(address_data),
    id_shipping_address: address_id,
    country_id: "IN",
    user_email: user ? user.email : "",
  };

  return API.post(url, data);
};

export const appGetOrders = (page_number, item_count) => {
  const url = `${API_ENDPOINT}/appGetOrders`;
  let data = {
    item_count: item_count,
    page_number: page_number,
  };

  return API.post(url, data);
};

export const appGetOrderDetails = (data) => {
  const url = `${API_ENDPOINT}/appGetOrderDetails`;
  return API.post(url, data);
};

export const appSaveReceiptIntimation = (receipt_id) => {
  const url = `${API_ENDPOINT}/appSaveReceiptIntimation`;
  let data = {
    receipt_id: receipt_id,
  };
  return API.post(url, data);
};

export const appReceiptOnUpload = (receipt_id,uploadfile) => {
  const url = `${API_ENDPOINT}/appReceiptOnUpload`;
  let data = {
    receipt_id: receipt_id,
    receipt_file: uploadfile,
  };
  return API.post(url, data);
};


export const appUpdateProfile = (personal_info) => {
  const url = `${API_ENDPOINT}/appUpdateProfile`;
  let data = {
    personal_info: JSON.stringify(personal_info),
  };

  return API.post(url, data);
};


export const appReorder = (order_id) => {
  const url = `${API_ENDPOINT}/appReorder`;
  let data = {
    order_id: order_id,
  };

  return API.post(url, data);
};


// Reset Password APIS
// API to send url and otp to reset user's password
export const appSendResetPasswordOTP = (loginId) => {
  const url = `${API_ENDPOINT}/appSendResetPasswordOTP`;
  let data = {
    login_id: loginId,
  };

  return API.post(url, data);
};

// API to verify token in case of url verification
export const appVerifyResetPasswordToken = (id, token) => {
  const url = `${API_ENDPOINT}/appVerifyResetPasswordToken`;
  let data = {
    id: id,
    token: token,
  };

  return API.post(url, data);
};

// API to verify OTP
export const appVerifyResetPasswordOTP = (id, otp) => {
  const url = `${API_ENDPOINT}/appVerifyResetPasswordOTP`;
  let data = {
    id: id,
    otp: otp,
  };

  return API.post(url, data);
};

// API to reset password
export const appResetPassword = (id, token, password) => {
  const url = `${API_ENDPOINT}/appResetPassword`;
  let data = {
    id: id,
    token: token,
    password: password,
  };

  return API.post(url, data);
};

/**
 * @BOC
 * @Scenario #135563-Scenario 1 A ( Updated Requirements )
 * @author Maanik Arya 
 * @date 30-Nov-2022
 * @use_of_code Added the confirmAndChange API and also Add the changePaymentAPI 
 */

 export const confirmAndChange = (order_id , action) => {
  const url = `${API_ENDPOINT}/confirmAndChange` ; 

  let data = {
    order_id: order_id,
    action : action , 
  };

  return API.post( url , data) ; 
}

export const changePaymentMode = (order_id , payment_mode) => {
  const url = `${API_ENDPOINT}/changePaymentMode` ; 

  let data = {
    order_id: order_id,
    payment_mode : payment_mode, 
  };

  return API.post( url , data) ; 
}

export const orderExtraMessages = (order_id , action ) =>{

  const url = `${API_ENDPOINT}/extraMessages` ; 

  let data = {
    order_id : order_id ,  
    action : action ,  
  }

  return API.post( url , data ) ;
}


/**
 * @EOC
 */


/**
 * BOC
 * @Task#143908 Need to make the Designer page from Static to Dynamic
 * author Maanik Arya 
 * date 31-OCt-2023
 * @use_of_code:- The below code is used to call the appGetDesigners API which is used to display the designer on hte designer page dynamically.
 */
export const appGetDesigners = () =>{
  const url = `${API_ENDPOINT}/appGetDesigners` ; 
  return API.post(url) ;
}
/**
 * EOC
 */

/**
 * BOC
 * @Task#143908 Need to make the Designer page from Static to Dynamic
 * author Maanik Arya 
 * date 31-OCt-2023
 * @use_of_code:- The below code is used to call the appGetDesigners API which is used to display the designer on hte designer page dynamically.
 */
export const appGetMenuDesigners = () =>{
  const url = `${API_ENDPOINT}/appGetMenuDesigners` ; 
  return API.post(url) ;
}
/**
 * EOC
 */