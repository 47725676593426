import React from "react";
import Header from "../../components/Header/Header";
import { useParams, useNavigate } from "react-router-dom"; // Import the useParams() hook from react-router-dom
import "./ExtraMessages.css"; // Import the styles
import { useEffect } from "react";
import { orderExtraMessages } from "../../services/AccountServices";
import { useState } from "react";

import { formatDate } from "../../utils/UtilityMethod"; //Import the method to format the date

const ExtraMessages = () => {
  const navigate = useNavigate();
  const { order_id, action } = useParams();
  const [data, setData] = useState();
  const [loader, setLoader] = useState("");
  const [showOrderDetails, setShowOrderDetails] = useState(false); // UseState that will toggle the order details section
  const [showReceiptDetails, setShowReceiptDetails] = useState(true); // UseState that will toggle the receipt details section
  const [showBankDetails, setShowBankDetails] = useState(true); // UseState that will toggle the bank details section

  useEffect(() => {
    setLoader("loader");
    orderExtraMessages(order_id, action).then((res) => {
      try {
        res = JSON.parse(res?.data);
        console.log("Useeffect23: ", res);

        if (res?.navigate) {
          navigate(res?.path);
        }
        setData(res);
        // If the receipt_details_visibility is false then hide the receipt details section
        if (res?.order_details?.receipt_details_visibility === false) {
          setShowReceiptDetails(false); // Hide the receipt details section
        }

        setLoader("");
      } catch (e) {
        navigate("/");
      }
    });
  }, []);

  return (
    <>
      {/* Header */}
      <Header
        mobileHeaderOptions={{
          menuIcon: false,
          wishlistIcon: true,
          darveysLargeTextIcon: true,
          searchIcon: false,
          shoppingBagIcon: false,
          backButtonIcon: true,
          pageHeadingShow: false,
          pageHeadingText: "",
          darveysSmallIcon: false,
          userAccountIcon: false,
          extraMessagesPage : true,
        }}
      />

      <div className={loader}></div>

      <section class="main-section extra-message">
        <div class="extra-messages-content">
          <div className="extra-messages-heading">
            <h4>{data?.heading}</h4>
          </div>

          <div className="extra-messages-subheading">
            <p>{data?.subHeading}</p>
          </div>

          {data?.note_1 && (
            <div className="extra-messages-note-1">
              <p style={{ "max-width": "600px", margin: "auto" }}>
                {data?.note_1}
                <br />
                {data?.note_1_line_2}
                <br />
                {data?.note_1_line_3}
              </p>
            </div>
          )}

          {data?.note_2 && (
            <div className="extra-messages-note-2">
              <p>{data?.note_2}</p>
            </div>
          )}

          {data?.note_3 && (
            <div className="extra-messages-note-3">
              <p>{data?.note_3}</p>
            </div>
          )}

          {data?.payu_button_visible && (
            <>
              <a href={data?.payu_url} className="btn custom-btn-inline me-2">
                Pay Now
              </a>
            </>
          )}

          {data?.further_assistance_line_1 && (
            <p>
              {data?.further_assistance_line_1} <br />{" "}
              {data?.further_assistance_line_2}
            </p>
          )}

          {/* <!-- ORDER SUMMARY SECTION STARTS  --> */}
          {data?.order_details?.order_details_visiblilty && (
            <div className="extramessages-order-summary-section">
              <div className="custom-accordion-item">
                <div className="custom-accordion-header">
                  <h2>
                    <button
                      className="btn accordion-action-btn"
                      onClick={() => {
                        setShowOrderDetails(!showOrderDetails);
                      }}
                      style={showOrderDetails ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                    >
                      <div>Order details</div>
                      <div className="accordion-icon-state">
                        {showOrderDetails ? (
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/Minus-icon.png"
                            }
                            style={{
                              width: "13px",
                              height: "13px",
                              marginLeft: "8px",
                            }}
                          />
                        ) : (
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/Plus-icon.png"
                            }
                            style={{
                              width: "13px",
                              height: "13px",
                              marginLeft: "8px",
                            }}
                          />
                        )}
                      </div>
                    </button>
                  </h2>
                </div>
                {showOrderDetails  && (
                    <div className="custom-accordion-content">
                    <ul className="accordion-type-list">
                    { data?.order_details?.order_number && (
                        <li>
                        <div className="sub-detail">
                            <div className="left">Order Number</div>
                            <div className="center">:</div>
                            <div className="right">
                            <div className="price">{data?.order_details?.order_number}</div>
                            </div>
                        </div>
                        </li>
                    )}
                    {data?.order_details?.sub_total !== 0 && (
                        <li>
                        <div className="sub-detail">
                            <div className="left">Sub total</div>
                            <div className="center">:</div>
                            <div className="right">
                            <div className="price">{data?.order_details?.sub_total}</div>
                            </div>
                        </div>
                        </li>
                    )}
                    {data?.order_details?.gift_voucher_discount !== 0 && (
                        <li>
                            <div className="sub-detail">
                            <div className="left">Gift Voucher</div>
                            <div className="center">:</div>
                            <div className="right">
                                <div className="price">{data?.order_details?.gift_voucher_discount}</div>
                            </div>
                            </div>
                        </li>
                    )}
                    {data?.order_details?.delivery_amount !== 0 && (
                        <li>
                        <div className="sub-detail">
                            <div className="left">Payable at delivery</div>
                            <div className="center">:</div>
                            <div className="right">
                            <div className="price">{data?.order_details?.delivery_amount}</div>
                            </div>
                        </div>
                        </li>
                    )}
                    {data?.order_details?.payment_method?.name && (
                        <li>
                        <div className="sub-detail">
                            <div className="left">Payment mode</div>
                            <div className="center">:</div>
                            <div className="right">
                            <div className="price">{data?.order_details?.payment_method?.name}</div>
                            </div>
                        </div>
                        </li>
                    )}
                    </ul>
                    </div>
                )}
              </div>
            </div>
          )}
          {/* <!-- ORDER SUMMARY SECTION ENDS  --> */}

          {/* <!-- RECEIPTS DETAILS SECTION STARTS  --> */}
          {data?.order_details?.advance_payment_details?.length > 0 && (
            <div className="extramessages-receipts-summary-section">
              <div className="custom-accordion-item">
                <div className="custom-accordion-header">
                  <h2>
                    <button
                      className="btn accordion-action-btn"
                      onClick={() => {
                        setShowReceiptDetails(!showReceiptDetails);
                      }}

                      style={showReceiptDetails ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                    >
                      <div>Receipt details</div>
                      <div className="accordion-icon-state">
                        {showReceiptDetails ? (
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/Minus-icon.png"
                            }
                            style={{
                              width: "13px",
                              height: "13px",
                              marginLeft: "8px",
                            }}
                          />
                        ) : (
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/Plus-icon.png"
                            }
                            style={{
                              width: "13px",
                              height: "13px",
                              marginLeft: "8px",
                            }}
                          />
                        )}
                      </div>
                    </button>
                  </h2>
                </div>
                {data?.order_details?.advance_payment_details?.map(
                  (advance_payment_details, index) => {
                    return (
                      <>
                        <div className="custom-accordion-content">
                          <ul className="accordion-type-list">
                            {showReceiptDetails &&
                              Object.entries(advance_payment_details).map(
                                ([key, val]) => {
                                  return (
                                    <>
                                      {key === "receipt_id" && (
                                        <li>
                                          <div className="sub-detail">
                                            <div className="left">
                                              Receipt no
                                            </div>
                                            <div className="center">:</div>
                                            <div className="right">
                                              <div className="price">{val}</div>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                      {key === "receipt_status" && (
                                        <li>
                                          <div className="sub-detail">
                                            <div className="left">
                                              Receipt status
                                            </div>
                                            <div className="center">:</div>
                                            <div className="right">
                                              <div className="price">{val}</div>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                      {key === "receipt_amount" && (
                                        <li>
                                          <strong>
                                            <div className="sub-detail">
                                              <div className="left">
                                                Amount payable
                                              </div>
                                              <div className="center">:</div>
                                              <div className="right">
                                                <div className="price">
                                                  {val}
                                                </div>
                                              </div>
                                            </div>
                                          </strong>
                                        </li>
                                      )}
                                      {key === "receipt_payment_mode_text" && (
                                        <li>
                                          <div className="sub-detail">
                                            <div className="left">
                                              Payment mode
                                            </div>
                                            <div className="center">:</div>
                                            <div className="right">
                                              <div className="price">{val}</div>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                      {key === "courier_partner" && val && (
                                        <li>
                                          <div className="sub-detail">
                                            <div className="left">
                                              Courier partner
                                            </div>
                                            <div className="center">:</div>
                                            <div className="right">
                                              <div className="price">{val}</div>
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                      {key === "tracking_number" && val && (
                                        <li>
                                          <div className="sub-detail">
                                            <div className="left">
                                              Tracking number
                                            </div>
                                            <div className="center">:</div>
                                            <div className="right">
                                              <div className="price">{val}</div>
                                            </div>
                                          </div>
                                        </li>
                                      )}

                                      {/** Pay Now button */}
                                      {key === "payupayment_link" && val && (
                                        <div
                                          className="text-start"
                                          style={{ marginBottom: "50px" }}
                                        >
                                          <a
                                            href={val}
                                            className="btn custom-btn-inline me-2"
                                          >
                                            PAY NOW
                                          </a>
                                        </div>
                                      )}
                                    </>
                                  );
                                }
                              )}
                          </ul>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            </div>
          )}
          {/* <!-- RECEIPTS DETAILS SECTION ENDS  --> */}

          {/* <!-- BANK DETAILS SECTION STARTS  --> */}
          {data?.order_details?.bank_details &&
            Object.entries(data?.order_details?.bank_details).length > 0 && (
              <div className="extramessages-bank-details-section">
                <div className="custom-accordion-item">
                  <div className="custom-accordion-header">
                    <h2>
                      <button
                        className="btn accordion-action-btn"
                        onClick={() => {
                          setShowBankDetails(!showBankDetails);
                        }}
                      >
                        <div>Bank details</div>
                        <div className="accordion-icon-state">
                          {showBankDetails ? (
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/Minus-icon.png"
                              }
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "8px",
                              }}
                            />
                          ) : (
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/Plus-icon.png"
                              }
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "8px",
                              }}
                            />
                          )}
                        </div>
                      </button>
                    </h2>
                  </div>
                  {showBankDetails && (
                    <div className="custom-accordion-content">
                      {/* <!-- BANK SUMMARY SECTION STARTS  --> */}
                      <ul className="accordion-type-list">
                        {Object.entries(data?.order_details?.bank_details).map(
                          ([key, val]) => {
                            return (
                              <>
                                {key === "Company name" && (
                                  <li>
                                    <div className="sub-detail">
                                      <div className="left">{key}</div>
                                      <div className="center">:</div>
                                      <div className="right">
                                        <div className="price">{val}</div>
                                      </div>
                                    </div>
                                  </li>
                                )}

                                {key === "Bank name" && (
                                  <li>
                                    <div className="sub-detail">
                                      <div className="left">{key}</div>
                                      <div className="center">:</div>
                                      <div className="right">
                                        <div className="price">{val}</div>
                                      </div>
                                    </div>
                                  </li>
                                )}

                                {key === "Account number" && (
                                  <li>
                                    <div className="sub-detail">
                                      <div className="left">{key}</div>
                                      <div className="center">:</div>
                                      <div className="right">
                                        <div className="price">{val}</div>
                                      </div>
                                    </div>
                                  </li>
                                )}

                                {key === "IFSC code" && (
                                  <li>
                                    <div className="sub-detail">
                                      <div className="left">{key}</div>
                                      <div className="center">:</div>
                                      <div className="right">
                                        <div className="price">{val}</div>
                                      </div>
                                    </div>
                                  </li>
                                )}

                                {key === "MICR code" && (
                                  <li>
                                    <div className="sub-detail">
                                      <div className="left">{key}</div>
                                      <div className="center">:</div>
                                      <div className="right">
                                        <div className="price">{val}</div>
                                      </div>
                                    </div>
                                  </li>
                                )}

                                {key === "Address" && (
                                  <li>
                                    <div className="sub-detail">
                                      <div className="left">{key}</div>
                                      <div className="center">:</div>
                                      <div className="right">
                                        <div className="price">{val}</div>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </>
                            );
                          }
                        )}
                      </ul>
                      {/* <!-- BANK SUMMARY SECTION ENDS  --> */}
                    </div>
                  )}
                </div>
              </div>
            )}
          {/* <!-- BANK DETAILS SECTION ENDS  --> */}

          <p className="note-msg">{data?.below_note}</p>
          <div className="extra-message-btn">
            {/**
             * BOC
             * Task#137377-Checkout Process Development for the App
             * author Mohammad MEraj
             * date 10-Apr-2023
             * use_of_code:- Adding the href to redirect to the home page to detect the URL in the app to redirect the home page on the app
             */}
            <button
              className="btn custom-solid-btn"
              data-bs-toggle="modal"
              data-bs-target="#customModal"
              onClick={() => {
                navigate("/");
              }}
            >
              CONTINUE SHOPPING
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExtraMessages;
