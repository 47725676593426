import React from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useDispatch } from "react-redux";
import { socialLogin } from "../../store/actions/authActions";
import config from "../../config/app.js";
//const APP_ID = "1122194058318324";
const APP_ID = "2285244038223895";

function FacebookAuth() {
  const dispatch = useDispatch();

  const onFacebookLogin = (res) => {
    console.log(res);
    const userObj = res;
    // if (res.status === "success") { 
    console.log(userObj);
    if (userObj?.email !== "") {
      const userDetails = {
        first_name: userObj?.name,
        last_name: "",
        telephone: "",
        country_id: "",
        email: userObj?.email,
        dob: "",
        password: "",
        user_type: "guest",
        social_login: "yes",
        is_seller: "0",
        city: "",
        postcode: "",
        company: "",
        confirm: "",
        fax: "",
        address_1: "",
        address_2: "",
      };

      dispatch(socialLogin(userDetails, "facebook")).then((data) => {
        if (!data) {
          // setSubmitting(false);
        }
      })
      .finally(() => {
        // setSubmitting(false);
      });;
    }
  };
  return (
    <div>
      <FacebookLogin
        appId={APP_ID}
        fields="name,email,picture"
        callback={onFacebookLogin}
        render={renderProps => (
          // BOC - Dixit Chauhan - 04-Jan-2024 - Task#143723-Need to fix the UI design issues - I have added the code to update FB Login icon
          <a onClick={renderProps.onClick} href="#0"><img width="25" src={config.skinURL + "/assets/images/icons/Facebook.svg"} alt="" /></a>
          // EOC 
          )}
      />
    </div>
  );
}

export default FacebookAuth;
