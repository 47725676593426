import React from "react";


function HeaderTopContent({ content }) {
  return (
    <div className="header-top-content-wrapper">
      <p>
        Free express shipping within 2-5 business days on all the orders worth $350 and above.
      </p>
    </div>
  );
}

export default HeaderTopContent;