import React from "react";
import useScroll from "../../hooks/useScroll";
import SearchModal from "../Search/SearchModal";
import CommonNav from "./CommonNav";
import DesignerNav from "./DesignerNav";
import KidsNav from "./KidsNav";
import OffersNav from "./OffersNav";
import "./WideNav.css";

function WideNav({ catMenus }) {
  const [isScroll] = useScroll();

  return (
    <div
      id="wide-nav"
      className={`site-head_menuBar background--black ${
        isScroll ? " sticky " : ""
      }`}
    >
      <nav className="site-nav" id="site-nav" aria-hidden="false">
        <ul id="site-nav__main" className="site-nav__main">
          {catMenus?.map((menu, idx) => {
            return (
              <React.Fragment key={`mainMenu-${idx}`}>
                {getSubMenuComponent(menu)}
              </React.Fragment>
            );
          })}
          <div className="bg-header"></div>
        </ul>

        <SearchModal />
      </nav>
    </div>
  );
}

const getSubMenuComponent = (menu) => {
  let component = null;
  switch (menu?.name) {
    case "Kids":
      component = <KidsNav menu={menu} />;
      break;
    case "Designers":
      component = <DesignerNav menu={menu} />;
      break;
    case "Sale":
      component = <OffersNav menu={menu} />;
      break;
    default:
      component = <CommonNav menu={menu} />;
      break;
  }
  return component;
};

export default WideNav;
