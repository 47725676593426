import React from 'react'

function PhoneBy() {
    return (
      <div id="phone_by" className="">
        <section className="phone-by bg-light">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="phone-available">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="d-dsk">
                      Email: Available 24/7, Phone/WhatsApp: 24 Hours Monday to Friday
                      </p> {/* Changed Phone by Text from "Phone : Available Monday to Saturday<br/> 9:30 am - 7:30 pm" to "Email: Available 24x7, Phone/WhatsApp: 24x7 Monday to Friday" */}
                      <p className="d-mbl">
                        Email: Available 24/7, Phone/WhatsApp: 24 Hours Monday to Friday
                      </p> {/* Changed Phone by Text from "Phone : Available Monday to Saturday<br/> 9:30 am - 7:30 pm" to "Email: Available 24x7, Phone/WhatsApp: 24x7 Monday to Friday" */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="clearfix"></div>
      </div>
    );
}

export default PhoneBy
