import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/authActions";
import "./Login.css";
import GoogleAuth from "./GoogleAuth";
import FacebookAuth from "./FacebookAuth";
import AppleAuth from "./AppleAuth";
import { FloatingLabel, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { ErrorsMsg, PLEASE_WAIT_TXT } from "../../utils/Constant";
import { LOGIN_FAIL } from "../../store/actions/types";
import { appSendLoginOtp } from "../../services/AuthServices";
import {isMacOs, isIOS} from "react-device-detect";


const schema = yup.object().shape({
  email: yup.string().email(ErrorsMsg.validEmail).required(ErrorsMsg.required), // Added the email validation
  password: yup.string().required(ErrorsMsg.required),
});

function Login({ active, toggleAuthFormCb, setVerifyOTPResponse,saveAppleResponseFunction }) {
  const dispatch = useDispatch();

  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpSubmitting, setisOtpSubmitting] = useState(false);

  const error = useSelector((state) => state.error);
  useEffect(() => {
    if (error.id === LOGIN_FAIL) {
      setMsg(error.msg);
    } else {
      setMsg("");
    }
  }, [error]);


  if (!active) return null;

  const onSubmitSuccess = (values, { setSubmitting }) => {
    dispatch(login(values))
      .then((data) => {
        if (!data) {
          setSubmitting(false);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onOtpClick = (user_id) => {
    if(user_id) {
      setisOtpSubmitting(true);
      appSendLoginOtp({login_id: user_id}).then(res => {
        res = JSON.parse(res.data);
        if(res?.status === 'success') {
          setVerifyOTPResponse(res, user_id);
          setisOtpSubmitting(false);
          toggleAuthFormCb("otplogin");
        }
        else {
          setisOtpSubmitting(false);
          setMsg(res?.message || "Something went wrong. Please try again.");
        }
      })
    }
  }

  const saveAppleResponse = (res) => {
    saveAppleResponseFunction(res);
  }

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmitSuccess}
        initialValues={{
          email: "",
          password: "",
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FloatingLabel
              controlId="email"
              label="Email"
              className="mb-3"
            > {/*Changed the label to the email from emial/phone number as per the requirements */}
              <Form.Control
                type="email"
                name="email"
                autoComplete="off"
                placeholder="name@example.com"
                value={values.email}
                onChange={handleChange}
                // onBlur={handleBlur}
                isInvalid={touched.email && errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel
              controlId="password"
              label="Password"
              className="mb-3"
            >
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="off"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                // onBlur={handleBlur}
                isInvalid={touched.password && errors.password}
              />

              {!errors.password && (
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/${
                    !showPassword ? "eye-show.png" : "eye-hide.png"
                  }`}
                  alt=""
                  className="eye-icon"
                  style={{
                    width: "1.125em",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => setShowPassword((p) => !p)}
                />
              )}

              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </FloatingLabel>

            {msg.length > 0 ? <span className="text-danger">{msg}</span> : null}

            <button
              className="btn btn-primary mt-35"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? PLEASE_WAIT_TXT : "LOGIN"}
            </button>
            <div className="row">
              <div className="col-md-6 col-6">
                <button
                  className="btn-blank mt-15"
                  type="button"
                  onClick={(e) => toggleAuthFormCb("forgotpwd")}
                >
                  <p className="left-text-mrgn">
                    Forgot Password<span className="questionmark">?</span>
                  </p>
                </button>
              </div>
              <div className="col-md-6 col-6">
                <button
                  className="btn-blank mt-15"
                  type="button"
                  disabled={errors.email}
                  onClick={(e) => onOtpClick(values.email)}
                  // onClick={(e) => e.preventDefault()}
                >
                  {/* <p className="text-right right-text-mrgn">Login with OTP</p> */}
                  <p
                    className={`text-right right-text-mrgn ${
                      !errors.email && values.email ? "text-black" : ""
                    }`}
                  >
                    {/* Login with OTP */}
                    {isOtpSubmitting ? PLEASE_WAIT_TXT : "Login with OTP"}
                  </p>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="social-login mt-40">
        <p>or login with</p>
        <ul className="mt-40 login-item-list">
          <li>
            <FacebookAuth />
          </li>
          <li>
            <GoogleAuth />
          </li>
          {(isMacOs || isIOS) ? <li><AppleAuth saveAppleResponse={saveAppleResponse}/></li> : ""}
        </ul>
        <button
          className="btn-blank text-underline mt-35"
          type="button"
          onClick={(e) => toggleAuthFormCb("signup")}
        >
          Create new account
        </button>
      </div>
    </>
  );
}
export default Login;
